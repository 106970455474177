import React, {useEffect, useState} from "react";
import { MaterialIcons } from '@expo/vector-icons';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import MyTenderCard from "../components/MyTenderCard";
import TenderCard from "../components/TenderCard";
import ArticleCard from "../components/ArticleCard";
import JournalTenderCard from "../components/JournalTenderCard";
import { AuthContext } from "../utils/context/auth";
import apiClient from "../utils/apiClient";
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { translate, localInfo } from "../utils/Translate";
import * as SplashScreen from 'expo-splash-screen';

import UserModel from "../models/UserModel";

import {
  Text, Button, Icon, Avatar, useColorModeValue, FlatList,
  Pressable, View, ScrollView, useTheme,
} from "native-base";
import CompanyCardV from "../components/CompanyCardV";
import SearchInput from "../components/SearchInput";
import UserNoTendersYet from "../components/UserNoTendersYet";
import CompanyCardVSkeleton from "../components/skeleton/CompanyCardVSkeleton";
import NoItemYet from "../components/NoItemYet";
import TenderCardSkeleton from "../components/skeleton/TenderCardSkeleton";
import MyTenderCardSkeleton from "../components/skeleton/MyTenderCardSkeleton";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

const Title = ({ title }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={28} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ navigation }) => {

  const isWeb = Platform.OS === 'web';

  const { authUser, isCompany } = React.useContext(AuthContext);
  const [userTenders, setUserTenders] = useState(null);
  const [tenders, setTenders] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [journalTenders, setJournal] = useState(null);
  const [user, setUser] = useState(UserModel)
  const [articles, setArticles] = useState()
  const [statistics, setStatistics] = useState({
    sent_offers: {
      total: 0,
      refused: 0,
      refused_percentage: 0,
      accepted: 0,
      accepted_percentage: 0
    },
  })

  const { colors } = useTheme();

  function getArticles(){
    apiClient.get(`articles?lang=${localInfo.local}`).then( async response => {
      setArticles(response.data.data)
    }).catch(async error => {
      console.log(error)
    });
  }
  function getUserTenders(id){
    apiClient.get(`users/${id}/tenders?lang=${localInfo.local}`).then( response => {
      setUserTenders(response.data.data)
    }).catch(error => console.log(error));
  }
  function getTenders(){
    apiClient.get(`tenders?lang=${localInfo.local}`).then( response => {
      setTenders(response.data.data)
    }).catch(error => console.log(error));
  }
  function getCompanies(){
    apiClient.get(`companies`).then( response => {
      setCompanies(response.data.data)
    }).catch(error => console.log(error));
  }
  function getCompanyStatistics(id){
    apiClient.get(`companies/${id}/statistics`).then( response => {
      setStatistics(response.data)
    }).catch(error => console.log(error));
  }
  function subscribeToNotification(expoToken){
    apiClient.post(`exponent/devices/subscribe`, {
      expo_token : expoToken
    }).then().catch(error => console.log(error));
  }
  function getJournal(){
    apiClient.get(`journal?lang=${localInfo.local}`).then( response => {
      setJournal(response.data.data)
    }).catch(error => console.log(error));
  }

  async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        // alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      // alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    return token;
  }

  const onSearch = (text) => {
    navigation.navigate('Tenders', {userId : user.id, searchValue: text})
  }

  useEffect(() => {
    (async() => {
      const user = await authUser()
      await SplashScreen.hideAsync();

      getUserTenders(user.id)
      getTenders()
      getJournal()
      getCompanies()
      getArticles()

      if (!!user.company_id){
        getCompanyStatistics(user.company_id)
      }

      setUser(user);
    })()

    registerForPushNotificationsAsync().then(token => subscribeToNotification(token));

    navigation.addListener('focus', () => {
      if (isCompany){
        (async() => {
          const user = await authUser()
          getUserTenders(user.id)
          getTenders()
          getJournal()
        })()
      }
    });
  }, [navigation]);

  const SeeAllButton = ({ screenToOpen, isUserTenders }) => {
    return <View mt={1} h={9} shadow={3} backgroundColor="appPrimary" borderRadius={14}>
      <Pressable  onPress={() => {
        navigation.navigate(
          screenToOpen, {
            userId: user.id,
            isUserTenders:  !!isUserTenders,
            companyId: user.company_id,
          })
      }}>
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} py={1} px={2} fontSize={18} fontWeight={600} color="primary.50">
          {translate('screens.home.seeAll')}
        </Text>
      </Pressable>
    </View>
  }

  const CompaniesSection = () => {
    return (
      <View>
        <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
          <Title title={translate('screens.home.companies')} />
          <SeeAllButton
            screenToOpen="Companies"
          />
        </View>
        {
          companies !== null ? (
            <FlatList
              data={companies}
              horizontal={true}
              scrollEnabled
              renderItem={({item}) => {
                return <CompanyCardV
                  companyName={item.name}
                  avatar={item.avatar}
                  tendersCount={item.tenders_count}
                  location={item.state.name[localInfo.local]}
                  id={item.id}
                />
              }}
              keyExtractor={item => item.id}
            />
          ) : null
        }
        {
          companies === null ? (
            (
              <FlatList
                data={[1,2,3,4]}
                horizontal={true}
                scrollEnabled
                renderItem={({item}) => {
                  return <CompanyCardVSkeleton/>
                }}
                keyExtractor={item => item}
              />
            )
          ) : null
        }
        {
          !!companies && !companies.length ? (
            <NoItemYet text={translate('components.noItemYet.noCompaniesYet')}/>
          ) : null
        }
      </View>
    );
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false} bg={useColorModeValue("white", "coolGray.800")}>
      <View mx={15} mt={4}>

        <View flexDirection="row" justifyContent="space-between">
          <View maxW="80%">
            <Title title={translate('screens.home.title')} />
          </View>
        </View>

        <SearchInput onSubmit={onSearch}/>

        {
          !isCompany ? (
            <CompaniesSection/>
          ) : null
        }

        {
          isCompany && !isWeb ? (
            <View mt={8} mb={3} flexDirection="row" justifyContent="space-between">
              <View>
                <View flexDirection="row">
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={32} fontWeight={500} color="appPrimary">
                    {translate('screens.home.welcome')}!
                  </Text>
                </View>
                <View mt={2} flexDirection="row">
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={22}>
                    {user.full_name}
                  </Text>
                  <Icon
                    as={MaterialIcons}
                    size="4"
                    ml={1}
                    name="check-circle"
                    color="appPrimary"
                  />
                </View>
                {
                  user.company_id === null ? null
                    :
                    <View>
                      {
                        localInfo.isRTL ? (
                          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                            {user.company.name} {translate('screens.home.at')} {user.company_role}
                          </Text>
                        ) : (
                          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18}>
                            {user.company_role} {translate('screens.home.at')} {user.company.name}
                          </Text>
                        )
                      }

                    </View>
                }

              </View>
              <Pressable
                onPress={() => navigation.navigate('MyProfile')}
              >
                <Avatar
                  mt={2}
                  size="xl"
                  source={{uri: user.avatar}}
                />
              </Pressable>
            </View>
          ) : null
        }

        {
          isCompany ? (
            <View>

              <View mt={8} flexDirection="row" justifyContent="space-between">
                <View maxW="80%">
                  <Title title={translate('screens.home.latestTenders')} />
                </View>
                <SeeAllButton
                  screenToOpen="Tenders"
                  isUserTenders={true}
                />
              </View>

              {
                !!userTenders && userTenders.length ? (
                  <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                    {userTenders.map((tender, index) => {
                      return (
                        <MyTenderCard
                          key={index}
                          id={tender.id}
                          title={tender.title}
                          branch={tender.branch.name[localInfo.local]}
                          description={tender.description}
                          date={tender.closing_date}
                          offers={tender.offers_count}
                        />
                      );
                    })}
                  </View>
                ) : null
              }
              {
                !!userTenders && !userTenders.length ? (
                  <UserNoTendersYet/>
                ) : null
              }
              {
                userTenders === null ? (
                  (
                    <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                      {[1,2,3].map((tender, index) => {
                        return (
                          <MyTenderCardSkeleton key={index}/>
                        );
                      })}
                    </View>
                  )
                ) : null
              }

              {
                Platform.OS !== "web" ? (
                  <View>
                    <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
                      <View maxW="80%">
                        <Title title={translate('screens.home.historyStatistics')} />
                      </View>
                      <SeeAllButton
                        screenToOpen="Statistics"
                      />
                    </View>

                    <View mt={2} justifyContent="space-around" flexDirection="row">
                      <View alignItems="center">
                        <AnimatedCircularProgress
                          size={120}
                          width={13}
                          fill={statistics.sent_offers.accepted_percentage}
                          tintColor={colors.appPrimary}
                          backgroundColor="grey"
                        >
                          {
                            () => (
                              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                                {statistics.sent_offers.accepted} Offers
                              </Text>
                            )
                          }
                        </AnimatedCircularProgress>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.sent_offers.accepted_percentage}%</Text>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>Offers accepted</Text>
                      </View>
                      <View alignItems="center">
                        <AnimatedCircularProgress
                          size={120}
                          width={13}
                          fill={statistics.sent_offers.refused_percentage}
                          tintColor={colors.appPrimary}
                          backgroundColor="grey"
                        >
                          {
                            () => (
                              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={600}>
                                {statistics.sent_offers.refused} Offers
                              </Text>
                            )
                          }
                        </AnimatedCircularProgress>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt={2} fontSize={16}>{statistics.sent_offers.refused_percentage}%</Text>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}>Offers rejected</Text>
                      </View>
                    </View>
                  </View>
                ) : null
              }

            </View>
          ) : null
        }

        <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
          <View maxW="80%">
            <Title title={translate('screens.home.recentTenders')} />
          </View>
          <SeeAllButton
            screenToOpen="Tenders"
          />
        </View>

        {
          !!tenders && tenders.length ? (
            <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
              {tenders.map((tender, index) => {
                return (
                  <TenderCard
                    key={index}
                    id={tender.id}
                    postTime={tender.created_at}
                    location={tender.state.name[localInfo.local]}
                    title={tender.title}
                    branch={tender.branch.name[localInfo.local]}
                    description={tender.description}
                    date={tender.closing_date}
                    offers={tender.offers_count}
                    tenderableType={tender.tenderable_type}
                    avatar={tender.tenderable.avatar}
                    isSaved={tender.has_favorited}
                  />
                );
              })}
            </View>
          ) : null
        }
        {
          !!tenders && !tenders.length ? (
            <NoItemYet text={translate('components.noTendersYet')}/>
          ) : null
        }

        {
          tenders === null ? (
            (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {[1,2,3].map((tender, index) => {
                  return (
                    <TenderCardSkeleton key={index}/>
                  );
                })}
              </View>
            )
          ) : null
        }

        {/*<Button*/}
        {/*  mt={5}*/}
        {/*  borderRadius={10}*/}
        {/*  backgroundColor="appSecondary"*/}
        {/*  onPress={() => navigation.navigate('Tenders', {userId : user.id})}*/}
        {/*>*/}
        {/*  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={600} fontSize={20} color="primary.50">*/}
        {/*    {translate('screens.home.exploreTenders')}*/}
        {/*  </Text>*/}
        {/*</Button>*/}

        {/* Journal Section */}
        <View>
          <View mt={8} flexDirection="row" justifyContent="space-between">
            <View maxW="80%">
              <Title title={"Other tenders"} />
            </View>
            <SeeAllButton
              screenToOpen="Journal"
              isUserTenders={true}
            />
          </View>

          {
            !!journalTenders && journalTenders.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {journalTenders.map((tender, index) => {
                  return (
                    <JournalTenderCard
                      key={tender.id}
                      id={tender.id}
                      title={tender.title}
                      branch={tender.branch.name[localInfo.local]}
                      state={tender.state.name[localInfo.local]}
                      description={tender.content}
                      date={tender.closing_date}
                    />
                  );
                })}
              </View>
            ) : null
          }
          {
            !!userTenders && !userTenders.length ? (
              <UserNoTendersYet/>
            ) : null
          }
          {
            userTenders === null ? (
              (
                <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                  {[1,2,3].map((tender, index) => {
                    return (
                      <MyTenderCardSkeleton key={index}/>
                    );
                  })}
                </View>
              )
            ) : null
          }
        </View>
        {/* End Journal Section */}

        {
          isCompany ? (
            <CompaniesSection/>
          ) : null
        }

        {
          !!articles ? (
            <View>
              <View mt={10} mb={3} flexDirection="row" justifyContent="space-between">
                <Title title={translate('screens.home.articles')} />
                <SeeAllButton
                  screenToOpen="Articles"
                />
              </View>

              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {
                  articles.map((article, index) => {
                    return (
                      <View key={index}>
                        <ArticleCard
                          article={article}
                        />
                      </View>
                    );
                  })
                }
              </View>
            </View>
          ) : null
        }

        <View mt={32} />

      </View>
    </ScrollView>
  );
}
