import React, {useState, useEffect, useRef} from 'react';
import {MaterialIcons, MaterialCommunityIcons, Entypo} from "@expo/vector-icons";

import {
  View, Text, Icon, Avatar, Center, ScrollView, Pressable,
  Input, InputGroup, InputLeftAddon, Button, ZStack, HStack, Spinner, useColorModeValue, Modal,
} from "native-base";
import apiClient from "../utils/apiClient";
import UserModel from "../models/UserModel";
import * as ImagePicker from "expo-image-picker";
import SelectList from "../components/SelectList";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";
import {getBase64File} from "../utils/FileTools";
import CountryStatesSelectList from "../components/CountryStatesSelectList";

const Title = ({ title, size = 28 }) => {
  return <View>
    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={size} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ route }) => {

  const { companyId } = route.params;

  const [showEdit, setShowEdit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isUpdatingCCP, setIsUpdatingCCP] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [updateCCPMessage, setUpdateCCPMessage] = useState('')
  const [withdrawMessage, setWithdrawMessage] = useState('')
  const [myProfile, setMyProfile] = useState(UserModel)
  const [companyAvatar, setCompanyAvatar] = React.useState(null);
  const [withdrawError, setWithdrawError] = React.useState(null);
  const [inputErrors, setInputErrors] = React.useState({});
  const [interestsUpdated, setInterestsUpdated] = React.useState('');
  const [updateIsLoading, setUpdateIsLoading] = React.useState(false);
  const [interestedStates, setInterestedStates] = useState([])
  const [interestedBranches, setInterestedBranches] = useState([])
  const [states, setStates] = useState([])
  const [branches, setBranches] = useState([])
  const [CCP, setCCP] = useState({
    name: null,
    address: null,
    account_number: null,
  })

  const webCompanyAvatar = useRef();

  const EditButton = () => (
    <View>
      <Button
        bg="appAccent"
        borderRadius={10}
        mb={4}
        leftIcon={<Icon as={MaterialIcons} name="mode-edit" size="sm" />}
        onPress={() => setShowEdit(true)}
      >
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
          {translate('misc.edit')}
        </Text>
      </Button>
    </View>
  )

  const SaveButton = () => (
    <View>
      <Button
        borderRadius={10}
        mb={4}
        leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
        onPress={() => updateMyProfile()}
      >

        {
          !isSaving ? (
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
              {translate('misc.save')}
            </Text>
          ) : null
        }
        {
          isSaving ? (
            <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
          ) : null
        }
      </Button>
    </View>
  )

  const SaveOrEdit = showEdit ? SaveButton : EditButton;

  const inputWidth = {
    base: "68%",
    md: "100%"
  }

  useEffect(() => {
    getMyProfile()
    getInterests()
    getBranches()
    getCCP()
  }, [])

  function getMyProfile(){
    apiClient.get(`users`).then( response => {
      setMyProfile(response.data)
    }).catch(error => console.log(error));
  }

  function updateMyProfile(){
    setIsSaving(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify(removeNulls(myProfile.company)))

    if (!!companyAvatar && Platform.OS !== "web"){
      data.append('avatar', {
        uri: companyAvatar.uri,
        type: `${companyAvatar.type}/${getFileType(companyAvatar)}`,
        name: getFileName(companyAvatar)
      })
    }
    if (Platform.OS === "web" && !!webCompanyAvatar){
      data.append('avatar', webCompanyAvatar.current)
    }

    apiClient.post(`companies`, data, {isFormData: true}).then( () => {
      setIsSaving(false)
      setShowEdit(false)
      getMyProfile()
    }).catch(error => {
      setIsSaving(false)
      console.log(error.response.data)
    });
  }

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }

  function getInterests(){
    apiClient.get(`users/1/interests`).then(response => {
      setBranchesOfLanguage(response.data.interestedBranches, localInfo.local, true)
      setStatesOfLanguage(response.data.interestedStates, localInfo.local, true)
    }).catch(error => console.log(error.response.data));
  }

  function getCCP(){
    apiClient.get(`companies/${companyId}/payoutinfos`).then( response => {
      setCCP(response.data)
    }).catch(error => console.log(error));
  }

  const withdraw = () => {
    setIsWithdrawing(true)
    apiClient.post(`companies/${companyId}/withdraw`).then( () => {
      setIsWithdrawing(false)
      setWithdrawMessage(translate('screens.companyProfile.withdrawRequestSent'))
    }).catch(error => {
      setIsWithdrawing(false)
      if (error.response.status === 400){
        setWithdrawMessage(translate('screens.companyProfile.withdrawRequestProcess'))
      }
      console.log(error.response.data)
    });
  }

  function setBranchesOfLanguage(branches, language, isInterests = false){
    if (isInterests){
      const allIds = branches.map(({id}) => id)
      setInterestedBranches(allIds)
      return;
    }
    let getBranches = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(getBranches)
  }

  function setStatesOfLanguage(states, language, isInterests = false){

    if (isInterests){
      const allIds = states.map(({id}) => id)
      setInterestedStates(allIds)
      return;
    }

    let getStates = states.map(state => ({
      id: state.id,
      name: language === 'ar' ? state.name.ar :
        language === 'fr' ? state.name.fr : state.name.en
    }))

    setStates(getStates);
  }

  const handleNameText = (text) => {
    setMyProfile({
      ...myProfile,
      company: {
        ...myProfile.company,
        name: text
      }
    })
  }
  const handleAddressText = (text) => {
    setMyProfile({
      ...myProfile,
      company: {
        ...myProfile.company,
        address: text
      }
    })
  }

  const handleWebsiteText = (text) => {
    setMyProfile({
      ...myProfile,
      company: {
        ...myProfile.company,
        website: text
      }
    })
  }

  function removeNulls(data){
    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    delete data.avatar

    return data;
  }

  const pickImage = async () => {
    let image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (image.cancelled) return

    if (Platform.OS === "web"){
      getBase64File(image.uri).then((file) => webCompanyAvatar.current = file)
    }

    setCompanyAvatar(image.assets[0]);
  };

  let onStateChanged = (item) => {
    setInterestedStates(item)

    let error = {...inputErrors}
    delete error.state
    setInputErrors(error);
  }

  let onBranchChanged = (item) => {
    setInterestedBranches(item)

    let error = {...inputErrors}
    delete error.branches
    setInputErrors(error);
  }

  const validateInterests = () => {

    if (interestedStates.length === 0) {
      setInputErrors({ ...inputErrors,
        state: translate('validation.states')
      });
      return false;
    } else if (interestedBranches.length === 0) {
      setInputErrors({ ...inputErrors,
        branches: translate('validation.branches2')
      });
      return false;
    }

    return true;
  };

  const validateWithdrawRequest = () => {

    if (!CCP.account_number && !CCP.address && !CCP.name) {
      setWithdrawError(translate('screens.companyProfile.fillBank'));
      return false;
    } else if (myProfile.company.balance < 10000) {
      setWithdrawError(translate('screens.companyProfile.minimumWithdrawal'));
      return false;
    }

    return true;
  };

  const handleUpdateInterests = () => {
    if (!validateInterests()) return

    setUpdateIsLoading(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      interestedBranches,
      interestedStates,
    }))

    apiClient.post(`companies`, data, {isFormData: true}).then( () => {
      setInterestsUpdated(translate('screens.companyProfile.interestsUpdated'))
      setUpdateIsLoading(false)
    }).catch(error => {
      console.log(error.response.data)
      setUpdateIsLoading(false)
    });
  }

  const handleUpdateCCP = () => {

    setIsUpdatingCCP(true)

    apiClient.put(`companies/${companyId}/payoutinfos`, CCP).then( () => {
      setIsUpdatingCCP(false)
      setUpdateCCPMessage(translate('screens.companyProfile.updated'))
    }).catch(error => {
      setIsUpdatingCCP(false)
      console.log(error.response.data)
    });
  }

  const handleWithdraw = () => {
    if (!validateWithdrawRequest()) return;

    setShowConfirmModal(true)
  }

  function getFileName(file){
    return file.uri.substring(file.uri.lastIndexOf("/") + 1);
  }
  function getFileType(file){
    return file.uri.substring(file.uri.lastIndexOf(".") + 1);
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>
        <View m={4}>
          <Title title={translate('screens.companyProfile.title')} />
        </View>

        {
          !!myProfile.id ? (
            <Center>
              <Pressable
                onPress={ showEdit ? pickImage : null}
              >
                <Avatar
                  mt={2}
                  size="2xl"
                  source={{ uri: !!companyAvatar ? companyAvatar.uri : myProfile.company.avatar }}
                />
              </Pressable>

              {
                showEdit ? (
                  <ZStack>
                    <View ml={8} mt={-12} bg={'#ccc'} p={2} rounded="full">
                      <Icon
                        as={<MaterialCommunityIcons name="camera"/>} color="appAccent" size={7}
                        onPress={ showEdit ? pickImage : null}
                      />
                    </View>
                  </ZStack>
                ) : null
              }

              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={30} >
                {
                  myProfile.company.name
                }
              </Text>
              {
                !!myProfile.company_id ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} >
                    { myProfile.company_role } at { myProfile.company.name }
                  </Text>
                ) : null
              }
            </Center>
          ) : null
        }

        {
          !!myProfile ? (
            <View bg="#F4F4F4" mx={4} my={8} p={3} borderRadius={10}>

              <SaveOrEdit />

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={24}>
                      <Icon as={<MaterialIcons name="badge" />} color={"appPrimary"} size={6} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('screens.companyProfile.name')}</Text>
                      </Center>
                    </View>
                  } />
                <Input
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  isReadOnly={!showEdit}
                  fontSize={16}
                  value={myProfile.company.name}
                  onChangeText={handleNameText}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.companyProfile.name')} />
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={24}>
                      <Icon as={<MaterialCommunityIcons name="map-marker" />} color={"appPrimary"} size={6} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('screens.companyProfile.address')}</Text>
                      </Center>
                    </View>
                  } />
                <Input
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  onChangeText={handleAddressText}
                  value={myProfile.company.address}
                  isReadOnly={!showEdit}
                  fontSize={16}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.companyProfile.address')} />
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={24}>
                      <Icon as={<MaterialCommunityIcons name="web" />} color={"appPrimary"} size={6} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('screens.companyProfile.website')}</Text>
                      </Center>
                    </View>
                  } />
                <Input
                  textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  onChangeText={handleWebsiteText}
                  value={myProfile.company.website}
                  isReadOnly={!showEdit}
                  fontSize={16}
                  bg="primary.50"
                  w={inputWidth} placeholder="https://" />
              </InputGroup>

            </View>
          ) : null
        }

        {
          branches.length === 0 ? null : <View>
            <View m={4} mt={2} mb={2}>
              <Title title={translate('screens.companyProfile.updateInterests')} size={24} />
            </View>

            <View bg="#F4F4F4" mx={4} my={4} p={3} borderRadius={10}>
              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={24}>
                      <Icon as={<MaterialCommunityIcons name="source-branch" />} color={"appPrimary"} size={6} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('misc.branches')}</Text>
                      </Center>
                    </View>
                  } />
                <View w={inputWidth}>
                  <SelectList
                    selectText={translate('misc.branches')}
                    modalHeaderText={translate('screens.auth.interests.selectBranches')}
                    doneText={translate('misc.done')}
                    cancelText={translate('misc.cancel')}
                    borderColor="gray.200"
                    roundedSize={2}
                    onDone={onBranchChanged}
                    alreadySelectedItems={interestedBranches}
                    items={branches}
                  />
                </View>
              </InputGroup>
              {
                'branches' in inputErrors ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                    {inputErrors.branches}
                  </Text>
                ) : null
              }

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={24}>
                      <Icon as={<MaterialCommunityIcons name="city" />} color={"appPrimary"} size={6} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('misc.states')}</Text>
                      </Center>
                    </View>
                  } />
                <View w={inputWidth}>
                  <CountryStatesSelectList
                    selectText={translate('misc.states')}
                    modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
                    doneText={translate('misc.done')}
                    cancelText={translate('misc.cancel')}
                    borderColor="gray.200"
                    roundedSize={2}
                    onDone={onStateChanged}
                    alreadySelectedItems={interestedStates}
                  />
                </View>
              </InputGroup>
              {
                'state' in inputErrors ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={2} color="appSecondary">
                    {inputErrors.state}
                  </Text>
                ) : null
              }

              {
                interestsUpdated.length > 0 ? (
                  <View mt={2}>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="appPrimary">
                      {interestsUpdated}
                    </Text>
                  </View>
                ) : null
              }

              <Button
                bg="appAccent"
                borderRadius={10} mt={10}
                onPress={handleUpdateInterests}
              >
                {
                  !updateIsLoading ? (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
                      {translate('misc.update')}
                    </Text>
                  ) : null
                }
                {
                  updateIsLoading ? (
                    <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                  ) : null
                }
              </Button>

            </View>
          </View>
        }

        <View>
          <View m={4} mt={2} mb={2}>
            <Title title={translate('screens.companyProfile.bankAccount')} size={24} />
          </View>

          <View bg="#F4F4F4" mx={4} my={4} p={2} borderRadius={10}>
            <InputGroup mb={1} >
              <InputLeftAddon
                children={
                  <View flexDirection="row" w={24}>
                    <Icon as={<MaterialIcons name="badge" />} color={"appPrimary"} size={5} />
                    <Center>
                      <Text
                        textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16}
                        fontWeight={500}>
                        {` ${translate('screens.companyProfile.name')}`}
                      </Text>
                    </Center>
                  </View>
                } />
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                 fontSize={16}
                 value={CCP.name}
                 onChangeText={(text) => {
                   setCCP({
                     ...CCP,
                     name: text
                   })
                 }}
                 bg="primary.50"
                 w={inputWidth} placeholder={translate('screens.companyProfile.name')} />
            </InputGroup>

            <InputGroup mb={1} >
              <InputLeftAddon
                children={
                  <View flexDirection="row" w={24}>
                    <Icon as={<MaterialCommunityIcons name="map-marker" />} color={"appPrimary"} size={5} />
                    <Center>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}>
                        {` ${translate('screens.companyProfile.address')}`}
                      </Text>
                    </Center>
                  </View>
                } />
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                 fontSize={16}
                value={CCP.address}
                onChangeText={(text) => {
                  setCCP({
                    ...CCP,
                    address: text
                  })
                }}
                 bg="primary.50"
                 w={inputWidth} placeholder={translate('screens.companyProfile.address')} />
            </InputGroup>

            <InputGroup mb={1} >
              <InputLeftAddon
                children={
                  <View flexDirection="row" w={24}>
                    <Icon as={<Entypo name="wallet" />} color={"appPrimary"} size={5} />
                    <Center>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}>
                        {` ${translate('screens.companyProfile.RIPNumber')}`}
                      </Text>
                    </Center>
                  </View>
                } />
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                 fontSize={16}
                value={CCP.account_number}
                onChangeText={(text) => {
                  setCCP({
                    ...CCP,
                    account_number: text
                  })
                }}
                 bg="primary.50"
                 w={inputWidth} placeholder={translate('screens.companyProfile.RIPNumber')} />
            </InputGroup>

            {
              updateCCPMessage.length > 0 ? (
                <View mt={2}>
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="appPrimary">
                    {updateCCPMessage}
                  </Text>
                </View>
              ) : null
            }

            <Button
              bg="appAccent"
              borderRadius={10} mt={10}
              onPress={handleUpdateCCP}
            >
              {
                !isUpdatingCCP ? (
                  <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
                    {translate('misc.update')}
                  </Text>
                ) : null
              }
              {
                isUpdatingCCP ? (
                  <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                ) : null
              }
            </Button>

          </View>
        </View>

        <View>
          <View m={4} mt={2} mb={2}>
            <Title title={translate('misc.balance')} size={24} />
          </View>

          <View bg="#F4F4F4" mx={4} my={4} p={3} borderRadius={10}>

            <HStack justifyContent="space-between">
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontWeight={500} fontSize={22} color="appPrimary">
                { myProfile.company.balance } {translate('misc.currency')}
              </Text>

              <Button
                bg="appAccent"
                borderRadius={10}
                onPress={handleWithdraw}
              >
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
                  {translate('misc.withdraw')}
                </Text>
              </Button>
            </HStack>

            <Text mt={4} textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17} color="appSecondary">
              { withdrawError }
            </Text>

          </View>
        </View>

        <View mb={32}/>

        {/* Confirm Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('misc.withdraw')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  {
                    !isWithdrawing && withdrawMessage.length === 0 ? (
                      <Text mt={3} fontSize={18} textAlign="center">
                        {translate('screens.companyProfile.wantToWithdraw')} {myProfile.company.balance} {translate('misc.currency')} ?
                      </Text>
                    ) : null
                  }
                  {
                    isWithdrawing && withdrawMessage.length === 0 ? (
                      <Spinner size="lg" color="appPrimary" accessibilityLabel="Loading..." />
                    ) : null
                  }
                  {
                    !isWithdrawing && withdrawMessage.length !== 0 ? (
                      <View mt={2}>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500}>
                          {withdrawMessage}
                        </Text>
                      </View>
                    ) : null
                  }

                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={withdraw}>
                    {translate('misc.withdraw')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
