export const arabic = {
  screens: {
    auth: {
      signIn: {
        email: "البريد الالكتروني",
        password: "كلمة السر",
        haveAccount: "ليس لديك حساب؟",
        easyTendersTool: "أداة سهلة للمناقصات",
        emailOrPassError: "إما البريد الإلكتروني أو كلمة المرور خاطئة",
        accountSuspended: "هذا الحساب موقوف",
        adminAccount: "لا يمكنك استخدام حساب المشرف الخاص بك هنا"
      },
      signUp: {
        companyName: "اسم الشركة",
        fullName: "الاسم الكامل",
        willSendPass: "سوف نرسل كلمة المرور",
        alreadyHaveAccount: "هل لديك حساب بالفعل؟",
        registration: "تسجيل",
        accountType: "نوع الحساب"
      },
      index: {
        heading: "البحث عن المناقصات وتقديم العروض في مكان واحد",
        heading2: "نظام شبه لامركزي",
        CreateAccount: "إنشاء حساب",
        note: "عن طريق التسجيل أو تسجيل الدخول فإنك توافق على",
        termsAndCondition: "الشروط والأحكام",
        andOur: "و",
        privacyPolicy: "سياسة الخصوصية"
      },
      addPhone: {
        phoneNumber: "رقم الهاتف",
        note: "سنرسل لك رمز التحقق"
      },
      companyInfo: {
        CNRCInfo: "معلومات الشركة",
        uploadCommercialRegister: "رفع السجل التجاري",
        frontPage: "الصفحة الأولى",
        backPage: "الصفحة الخلفية",
        notOwner: "أنا لست المالك",
        yourJobTitle: "المسمى الوظيفي",
        areYouStartup: "هل أنت شركة ناشئة؟",
        yesStartup: "نعم, أنا شركة ناشئة.",
        uploadLabel: "رفع العلامة",
        label: "العلامة",
      },
      interests: {
        selectInterests: "حدد الفروع والولايات التي تهمك",
        selectStates: "اختر الولايات",
        selectCountryStates: "اختر الدولة والولاية",
        selectCountryState: "اختر الدولة والولاية",
        selectBranches: "اختر الفروع",
        signUpModal: {
          headerTitle: "إنشاء حسابك",
          creatingText: "يتم إنشاء حسابك، الرجاء الإنتظار...",
          accountCreated: "تم إنشاء حسابك بنجاح.",
          passSent: "لقد أرسلنا كلمة المرور إلى بريدك الإلكتروني"
        }
      },
      verifyPhone: {
        verifyPhone: "التحقق من رقم الهاتف",
        enterCode: "أدخل الرمز المرسل إلى رقم هاتفك",
        weSentCode: "لقد أرسلنا رمزا إلى",
        invalidCode: "هذا الرمز غير صالح",
        captchaError: "خطأ في التحقق، الرجاء اعادة المحاولة"
      },
      underReview: {
        accountUnderReview: "حسابك قيد المراجعة",
        reviewingAccount: "نحن نقوم بمراجعة حسابك",
        checkBack: "عادة ما يستغرق ذلك أقل من 48 ساعة. يرجى إعادة المحاولة مرة أخرى قريبا.."
      }
    },
    home: {
      title: "الرئيسية",
      welcome: "مرحبًا",
      at: "في",
      latestTenders: "أحدث المناقصات",
      seeAll: "عرض الكل",
      historyStatistics: "السجل والإحصائيات",
      recentTenders: "المناقصات الجديدة",
      exploreTenders: "عرض المناقصات",
      articles: "مقالات",
      companies: "شركات",
      otherTenders: "مناقصات أخرى"
    },
    inbox: {
      title: "الرسائل",
      searchPlaceholder: "بحث..",
      inboxEmpty: "ليس لديك أية رسائل حتى الآن",
    },
    publishTender: {
      searchPlaceholder: "بحث..",
      title: "نشر المناقصة",
      noPermission: "عذرا ، أنت بحاجة إلى إذن من شركتك للقيام بهذا العمل",
      info: {
        description: "معلومات المناقصة",
        validation: {
          title: "العنوان ضروري",
          description: "الوصف ضروري",
          branch: "الفرع ضروري",
          state: "الدولة ضروري",
          language: "يرجى اختيار لغة",
          participation: "يرجى اختيار نوع المشاركة",
          participationCost: "يرجى إضافة السعر"
        },
        placeholder: {
          location: "المكان",
          title: "العنوان",
          description: "الوصف",
          language: "اللغة",
          participationType: "نوع المشاركة",
          cost: "التكلفة"
        },
        participationCostNote: "يرجى إضافة التكلفة بالدينار الجزائري",
        uploadFile: "رفع ملف (اختياري)",
        clickToUpload: "انقر لرفع ملف الصفقة",
        filesToUpload: "الملفات المطلوب رفعها",
        tenderLocation: "مكان المناقصة"
      },
      requiredDocuments:{
        title: "المستندات المطلوبة",
        modalHeader: "أضف الوثيقة المطلوبة",
        description: "هنا يمكنك أن تطلب من صاحب العرض إرفاق المستندات المطلوبة",
        document: "المستند",
        name: "إسم",
        add:{
          documentName: "اسم المستند",
          documentNote: "ملاحظة",
          required: "مطلوب"
        },
        requiredDocument: "هذا المستند مطلوب"
      },
      criteria: {
        title: "معايير الإختيار",
        description: "أضف ما يناسب صفقتك، ليتمكن المشاركون من ملئه",
        pointsTable: "جدول النقاط",
        addPointsTable: "إضافة جدول نقاط إلى المناقصة",
        priceTable: "جدول الأسعار",
        addPriceTable: "إضافة جدول أسعار إلى المناقصة",
        bid: "مزايدة",
        totalCost: "التكلفة الإجمالية لهذه المناقصة",
        closingDate: "سيتم إغلاق المناقصة في هذا اليوم",
        placeholder: {
          currency: "عملة",
          TVA: "الضريبة",
          initialPrice: "السعر الأولي",
          pickDate: "اختيار تاريخ الإغلاق"
        },
        validation: {
          initPrice: "السعر الأولي ضروري",
          currency: "العملة مطلوبة"
        }
      },
      pointsTable: {
        title: "إنشاء جدول النقاط",
        description: "قم بإنشاء جدول النقاط الخاص بك حتى يتمكن المشاركون من ملئه",
        addItem: "إضافة عنصر",
        addToTable: "إضافة إلى جدول النقاط",
        validation: {
          itemName: "اسم العنصر مطلوب",
          itemNumber: "رقم المنتج مطلوب",
          itemPoint: "نقاط العنصر مطلوبة"
        },
        placeholder: {
          itemName: "اسم العنصر",
          requirementNumber: "رقم المتطلب",
          numberOfPoints: "عدد النقاط لكل عنصر",
          maxPoints: "الحد الأقصى من النقاط"
        }
      },
      priceTable: {
        title: "إنشاء جدول الأسعار الخاص بك",
        description: "قم بإنشاء جدول الأسعار الخاص بك حتى يتمكن المشاركون من ملئه",
        addItem: "إضافة عنصر",
        addToTable: "اضف إلى جدول الاسعار",
        validation: {
          itemName: "اسم العنصر ضروري",
          itemUnit: "وحدة العنصر ضرورية",
          itemQuantity: "كمية العنصر ضرورية"
        },
        placeholder: {
          itemName: "اسم العنصر",
          unit: "وحدة",
          requiredQuantity: "الكمية المطلوبة"
        }
      },
      reviewSteps: {
        title: "خطوات المراجعة",
        description: "إضافة خطوات المراجعة التي يجب على مقدم العرض المحدد إكمالها",
        stepNumber: "رقم الخطوة",
        stepReviewers: "المراجعون لهذه الخطوة",
        reviewers: "المراجعون",
        addStep: "إضافة خطوة",
        validation: {
          title: "العنوان ضروري",
          description: "الوصف ضروري",
          action: "يرجى تحديد إجراء"
        },
        placeholder: {
          stepTitle: "عنوان الخطوة",
          description: "الوصف",
          action: "الإجراء"
        },
        actions: {
          none: "بدون إجراء",
          text: "النص",
          files: "الملفات"
        }
      }
    },
    articles: {
      title: "مقالات ونصائح",
      searchPlaceholder: "بحث.."
    },
    help: {
      title: "مساعدة",
      chat: "تواصل معنا",
      call: "خط الاتصال",
      facebookPage: "صفحة الفيسبوك",
      FAQ: "الأسئلة الشائعة"
    },
    tenders: {
      userTendersTitle: "مناقصاتك",
      tendersTitle: "المناقصات",
      Branches: "الفروع",
      states: "الولايات"
    },
    tender: {
      previewText: "معاينة لمناقصتك",
      share: "مشاركة",
      profile: "الملف الشخصي",
      message: "رسالة",
      reviewSteps: "خطوات المراجعة",
      receivedOffers: "العروض المستلمة",
      participationFees: "رسوم المشاركة",
      description: "الوصف",
      views: "مشاهدات",
      closingDate: "تاريخ الإغلاق",
      offers: "العروض",
      documents: "الوثائق",
      requiredDocuments: "المستندات المطلوبة",
      bid: "المزايدة",
      startingPrice: "السعر المبدئي",
      pointsTable: "جدول النقاط",
      priceTable: "جدول الأسعار",
      deliveryDate: "تاريخ التسليم",
      sendYourOffer: "أرسل عرضك",
      closed: "مناقصة مغلقة",
      apply: "تقديم عرض",
      preview: "معاينة",
      publishModal: {
        headerText: "نشر هذه المناقصة",
        areYouSure: "هل أنت متأكد؟",
        note: "لا يمكنك تعديل المناقصة بعد نشرها"
      },
      offerModal: {
        headerText: "أرسل هذا العرض",
        note: "هل ترغب في تقديم هذا العرض؟"
      },
      pointsTableHeader: {
        item: "العنصر",
        requirement: "المطلوب",
        points: "النقاط",
        offer: "العرض",
        total: "المجموع"
      },
      priceTableHeader: {
        item: "العنصر",
        unit: "الوحدة",
        quantity: "الكمية",
        price: "الثمن",
        total: "المجموع"
      },
      totalTableTitles: {
        TOTAL_HT: "مجموع بدون ضريبة",
        TVA: "الضريبة",
        TOTAL_TTC: "المجموع"
      }
    },
    ccp: {
      CCP: "الحساب البريدي",
      CCPInfo: "معلومات الحساب البريدي",
      fullName: "الاسم الكامل",
      RIP: "RIP",
      address: "العنوان",
      paymentProof: "إثبات الدفع",
      uploadPaymentProof: "انقر لرفع إثبات الدفع",
      IPayed: "تم الدفع",
      confirmPayment: "تأكيد الدفع",
      didYouPaid: "هل دفعت",
      YesIPaid: "نعم لقد دفعت"
    },
    chat: {
      message: "رسالة"
    },
    companyProfile: {
      title: "حساب الشركة",
      interestsUpdated: "الاهتمامات المحدثة",
      name: "اسم",
      address: "عنوان",
      website: "الموقع",
      updateInterests: "تحديث الاهتمامات",
      updated: "تحديث.",
      withdrawRequestSent: "تم ارسال طلب السحب.",
      withdrawRequestProcess: "طلب السحب الخاص بك قيد التنفيذ.",
      bankAccount: "الحساب بنكي",
      RIPNumber: "الرقم RIP",
      wantToWithdraw: "هل تريد السحب؟",
      fillBank: "يرجى ملء تفاصيل الحساب المصرفي أولاً",
      minimumWithdrawal: "رصيدك أقل من الحد الأدنى للسحب: 10,000.00 دينار",
    },
    myProfile: {
      title: "ملف تعريف",
      passwordUpdated: "تم تحديث كلمة المرور!",
      passwordError: "خطأ: كلمة المرور غير صحيحة",
      passwordError2: "خطأ: كلمة المرور الجديدة قصيرة جدا",
      fullName: "الاسم الكامل",
      birthDate: "تاريخ الميلاد",
      address: "العنوان",
      email: "البريد الإلكتروني",
      phone: "الهاتف",
      company: "شركة",
      updateInterests: "تحديث الاهتمامات",
      updatePassword: "تحديث كلمة المرور",
      oldPassword: "كلمة المرور القديمة",
      newPassword: "كلمة مرور الجديدة"
    },
    notifications: {
      title: "الإشعارات",
      markAsRead: "تسجيل كمقروء",
      earlier: "أسبق",
      new: "الجديد",
      from: "من",
      newTender: "مناقصة جديدة",
      newOffer: "عرض جديد",
      congrats: "مبروك",
      withdrawAccepted: "تم قبول طلب السحب",
      withdrawRejected: "تم رفض طلب السحب",
      paymentAccepted: "تم قبول إثبات الدفع",
      paymentRejected: "تم رفض إثبات الدفع الخاص بك",
      yourTenderOffers: "لقد حصلت على",
      yourTenderOffers2: "عروض جديدة",
      yourOfferAccepted: "تم قبول عرضك",
      yourOfferSelected: "تم إختيار عرضك",
      yourOfferCanceled: "تم إلغاء عرضك",
      yourOfferReplyAccepted: "تم قبول رد المراجعة الخاص بعرضك",
      yourOfferReplyRejected: "تم رفض رد المراجعة الخاص بعرضك",
      noNotificationsYet: "ليس لديك أي إشعارات حتى الآن",
      yourRequestAccepted: "لقد تم قبول طلب إنضمامك",
      yourRequestRejected: "لقد تم رفض طلب إنضمامك",
      yourGroupRequests: "لديك",
      yourGroupRequests2: "طلب إنظمام"
    },
    offer: {
      title: "عرض",
      reviewOptions: "خيارات المراجعة",
      reviewSteps: "خطوات المراجعة",
      cancelSelection: "إلغاء الإختيار",
      reasonOfAccepting: "سبب القبول",
      reasonOfCancelling: "سبب الإلغاء",
      reasonOfSelecting: "سبب الاختيار",
      summary: "ملخص",
      points: "النقاط",
      bidAmount: "مبلغ المزايدة",
      submissionDate: "تاريخ التقديم",
      pointsTableTitle: "جدول النقاط",
      priceTableTitle: "جدول الأسعار",
      totalTitle: "مجموع",
      selectOffer: "اختر العرض",
      selectThisOffer: "اختر هذا العرض",
      whyChooseOfferText: "يرجى كتابة توضيح موجز عن سبب اختيارك لهذا العرض؟ ضع في اعتبارك أن سبب هذا الاختيار سيتم عرضه لجميع المشاركين",
      rejectThisOffer: "رفض هذا العرض",
      whyRejectOfferText: "يرجى كتابة شرح موجز عن سبب رفضك لهذا العرض؟ ضع في اعتبارك أن سبب هذا الرفض سيتم عرضه على جميع المشاركين"
    },
    offerReview: {
      title: "مراجعة العرض",
      failed: "مرفوض",
      passed: "مقبول",
      points: "النقاط",
      yourOfferPoints: "نقاط العرض الخاصة بك",
      requiredPoints: "النقاط المطلوبة",
      result: "نتيجة",
      payAndSubmit: "الدفع وتقديم العرض",
      sendOffer: "أرسل هذا العرض",
      warning: "تحذير!",
      minimumPoints: "ليس لديك الحد الأدنى من النقاط!",
      submitAnyway: "إرسال على أي حال؟",
      submitOffer: "أرسل هذا العرض؟"
    },
    offerSent: {
      title: "تم إرسال العرض!",
      text: "ستتلقى تنبيها بشأن ما إذا كان عرضك قد تم اختياره أم لا في يوم الإغلاق",
      buttonText: "انتقل إلى الصفحة الرئيسية"
    },
    paymentMethods: {
      title: "طرق الدفع",
      paymentMethod: "طريقة الدفع",
      paymentReviewType: "طريقة التحقق من الدفع",
      paymentReviewDuration: "مدة التحقق من الدفع"
    },
    paymentReview: {
      title: "الدفع قيد المراجعة",
      text: "سيقوم فريقنا بمراجعة معاملة الدفع الخاصة بك ، وستتلقى تنبيها بإشعار ما إذا كان عرضك قد تم اختياره أم لا في يوم الإغلاق",
      buttonText: "انتقل إلى الصفحة الرئيسية"
    },
    receivedOffers: {
      title: "العروض المستلمة",
      closingDateMsg: "سيتم عرض العروض في تاريخ الإغلاق",
      selectedOffer: "العرض المختار",
      acceptedOffer: "العرض المقبول",
      tabs: {
        passed: "مقبول",
        failed: "مرفوض"
      }
    },
    reviewSteps: {
      title: "خطوات المراجعة",
      note: "ملاحظة: إذا تم قبول جميع الخطوات، سيتم قبول العرض تلقائيًا",
      replyUnReview: "ردك قيد المراجعة، ستتلقى إشعار بالتحديثات",
      replyRefused: "تم رفض ردك",
      reason: "السبب",
      reviewers: "المراجعون",
      uploadFiles: "رفع الملفات",
      clickToUpload: "انقر لرفع الملفات",
      reply: "إجابة",
      status: "الحالة",
      noReply: "لا يوجد رد حتى الآن",
      documents: "الوثائق",
      accept: "قبول",
      reject: "رفض",
      send: "ارسال",
      back: "رجوع",
      next: "التالي",
      rejectStep: "رفض هذه الخطوة",
      whyRejectStep: "يرجى كتابة شرح موجز عن سبب رفض هذه الخطوة؟",
      sendingReply: "إرسال الرد",
      confirmSendingReply: "هل أنت متأكد من إرسال هذا الرد؟",
      acceptReply: "قبول الرد",
      confirmAcceptReply: "هل ترغب في قبول هذا الرد؟",
      statuses: {
        pending: "معلق",
        refused: "مرفوض",
        accepted: "مقبول"
      }
    },
    reviewStepsPreview: {
      title: "خطوات المراجعة"
    },
    tenderClosed: {
      title: "المناقصة مغلقة",
      text: "تم اجتياز جميع خطوات المراجعة بنجاح",
      text2: "إغلاق المناقصة وإرسال إشعار إلى جميع مقدمي العروض",
      close: "غلق"
    },
    tenderPublished: {
      title: "نشر المناقصة",
      text: "تم نشر مناقصتك الجديدة بنجاح",
      text2: "سوف تتلقى إشعارات عندما يكون لديك عروض",
      goHome: "انتقل إلى الصفحة الرئيسية"
    },
    userProfile: {
      publishedTenders: "المناقصات المنشورة",
      noTenders: "لا توجد عطاءات لإظهارها."
    },
    companies: {
      title: "الشركات"
    },
    callLines: {
      paragraph1: "يرجى مراجعة صفحة 'الأسئلة الشائعة' ، أو الاتصال بفريق الدعم الفني لدينا من خلال النقر على زر 'تواصل معنا",
      paragraph2: "إذا لم تتمكن من العثور على المساعدة التي تبحث عنها ، فاتصل بنا على هذه الأرقام"
    },
    forgetPassword: {
      emailPage: {
        title: "هل نسيت كلمة المرور؟",
        description: "أدخل بريدك الإلكتروني المسجل لنرسل لك تعليمات إعادة تعيين كلمة المرور",
        rememberPassword: "تذكر كلمة المرور؟"
      },
      resetPage: {
        title: "تم إرسال البريد الإلكتروني!",
        description: "لقد أرسلنا رمزًا مكونًا من 6 أرقام إلى بريدك الإلكتروني ، استخدم هذا الرمز لإعادة تعيين كلمة المرور الخاصة بك.",
        code: "الرمز",
        codeRequired: "الرمز مطلوب",
        codeInvalid: "هذا الرمز غير صالح",
        resetPassword: "إعادة تعيين كلمة المرور",
        notReceived: "لم تتلقى الرمز؟"
      },
      successPage: {
        title: "إعادة تعيين كلمة المرور!",
        description: "تمت إعادة تعيين كلمة المرور الخاصة بك بنجاح."
      }
    }
  },
  components: {
    offerCard: {
      bestOffer: "أفضل عرض",
      selected: "المختار",
      accepted: "مقبول",
      pending: "معلق",
      rejected: "مرفوض",
      points: "النقاط",
      bidAmount: "مبلغ المزايدة",
      submissionDate: "تاريخ التقديم"
    },
    resend: {
      resend: "ارسال"
    },
    searchInput: {
      search: "بحث"
    },
    tenderCard: {
      posted: "نشرت",
      save: "تسجيل",
      report: "تبليغ",
      closingDate: "تاريخ الإغلاق",
      offers: "العروض",
      sendReport: "إرسال تبليغ",
      reason: "السبب"
    },
    userNoTendersYet: {
      noTenders: "ليس لديك مناقصات بعد!",
      createTender: "إنشاء مناقصة"
    },
    userWithTitle: {
      select: "اختر"
    },
    userNoGroupsYet: {
      noGroups: "ليس لديك مجموعات بعد!",
      createGroup: "إنشاء شراء جماعي",
      noGroupsYet: "لا توجد مجموعات بعد!",
      noRequestsYet: "لا توجد طلبات!"
    },
    selectList: {
      selectAll: "اختر الكل"
    },
    noTendersYet: "لا توجد مناقصات حتى الآن!",
    outgoingOfferCard: {
      yourPoints: "نقاطك"
    },
    noItemYet: {
      noCompaniesYet: "لا توجد شركات لعرضها",
      noOffers: "لا توجد عروض"
    },
    subscription: {
      oneMonth: "شهر واحد",
      threeMonths: "3 اشهر",
      sixMonths: "6 اشهر",
      oneYear: "سنة واحدة",
      billedMonthly: "شهريا",
      billedTrimsterly: "كل 3 أشهر",
      billedSemesterly: "كل 6 أشهر ",
      billedYearly: "كل عام",
      save: "وفر"
    },
    subscriptionExpired: {
      modalHeader: "انتهت صلاحية الاشتراك",
      modalText: "**يبدو أن اشتراكك قد انتهت صلاحيته.**",
      modalText2: "لمواصلة استخدام هذه الميزة، يرجى تجديد اشتراكك.",
      renewSubscription: "تجديد الاشتراك",
      back: "رجوع"
    }
  },
  drawer: {
    home: "الرئيسية",
    yourTenders: "مناقصاتك",
    teamManagement: "إدارة الفريق",
    SavedTenders: "المناقصات المحفوظة",
    outgoingOffers: "العروض الصادرة",
    downloads: "التحميلات",
    statistics: "الاحصائيات",
    settings: "اعدادات",
    subscriptions: "الاشتراكات",
    journal: "مجلة",
    content: {
      at: "في",
      signOut: "تسجيل الخروج",
      signOutQuestion: "هل ترغب في تسجيل الخروج؟"
    },
    screens: {
      teamManagement: {
        title: "إدارة الفريق",
        description: "إدارة أعضاء شركتك",
        select: "اختر",
        members: "اعضاء",
        addMember: "إضافة عضو جديد",
        email: "البريد الإلكتروني",
        fullName: "الاسم الكامل",
        memberRole: "دور العضو",
        userPermissions: "تصريحات المستخدم",
        sendInvitation: "إرسال دعوة",
        editMember: "تعديل العضو",
        deleteMember: "حذف عضو",
        deleting: "حذف",
        delete: "حذف",
        confirmDelete: "هل ترغب في حذف هذا العضو؟",
        permissions: {
          owner: "المالك",
          manageMembers: "إدارة الأعضاء",
          publishTenders: "نشر المناقصات",
          sendOffers: "إرسال العروض",
          readReplyMessages: "قراءة الرسائل",
          accessStatistics: "الإحصائيات",
          accessCompanySettings: "الوصول إلى إعدادات الشركة",
          manageGroupBuying: "إدارة الشراء الجماعي"
        }
      },
      savedTenders: {
        title: "المناقصات المحفوظة",
        description: "ابحث عن مناقصاتك المحفوظة",
        noTenders: "ليس لديك أي مناقصات محفوظة حتى الآن!"
      },
      outgoingOffers: {
        title: "العروض الصادرة",
        description: "هنا يمكنك متابعة التحديثات في المناقصات التي قدمت فيها العروض",
        noOffers: "ليس لديك أي عرض صادر حتى الآن!",
        browseTenders: "تصفح المناقصات"
      },
      downloads: {
        title: "التحميلات",
        description: "ابحث عن الملفات التي قمت بتنزيلها مع تفاصيلها.",
        noDownloads: "ليس لديك تنزيلات بعد!",
        fileItem:{
          source: "مصدر",
          here: "هنا",
          date: "تاريخ",
        }
      },
      subscriptions: {
        title: "الاشتراكات",
        yourSubscription: "اشتراكك الحالي",
        state: "الحالة",
        expireOn: "ينتهي في",
        expiredOn: "انتهى في",
        active: "نشط",
        expired: "منتهي",
        choosePaymentMethod: "اختر وسيلة الدفع"
      },
      statistics: {
        title: "الإحصائيات",
        description: "ملخص حول جميع أنشطة حسابك",
        totalOffersReceived: "إجمالي العروض المستلمة",
        offers: "العروض",
        offersAccepted: "العروض المقبولة",
        offersRejected: "العروض المرفوضة",
        totalOffersSent: "إجمالي العروض المرسلة"
      },
      settings: {
        title: "اعدادات",
        description: "إدارة إعدادات حساب شركتك واختيار تفضيلاتك",
        messaging: "المراسلة",
        receiveMessages: "تلقي رسائل من المستخدمين",
        appearance: "المظهر",
        language: "اللغة",
        deviceLanguage: "لغة الجهاز",
        arabic: "العربية",
        english: "الإنجليزية",
        french: "الفرنسية",
        notifications: "الإشعارات",
        receiveNotifications: "تلقي الإشعارات",
        account: "الحساب",
        deleteAccount: "حذف حسابي",
        deleteAccountConfirm: "هل أنت متأكد ؟، سيتم حذف حسابك وكل المعلومات المتعلقة بحسابك من منصتنا.",
        deleteCompany: "هل تريد حذف حساب الشركة أيضًا؟",
      }
    },
    groupBuying: {
      title: "الشراء الجماعي",
      myGroups: "مجموعاتي",
      create: "إنشاء شراء جماعي",
      created: "تم إنشاء الشراء الجماعي",
      createdMsg: "تم إنشاء الشراء الجماعي",
      previewText: "معاينة",
      requests: "الطلبات",
      itemsTable: {
        title: "جدول العناصر",
        description: "قم بإنشاء جدول العناصر الخاص بك حتى يتمكن المشاركون من ملئه",
        groupItemsTable: "جدول عناصر المجموعة",
      },
      publishModal: {
        headerText: "نشر",
        areYouSure: "هل أنت متأكد؟",
        note: "لا يمكنك التعديل بعد النشر"
      },
      apply: {
        yourRequest: "طلبك",
        modalHeader: "ارسل طلب",
        modalNote: "هل تريد إرسال هذا الطلب؟",
        requestSent: "تم ارسال الطلب",
        requestSentMsg: "تم ارسال طلبك.",
        join: "انضم"
      },
      tabs:{
        explore: "تصفح",
        myGroups: "مجموعاتي",
        myRequests: "طلباتي",
      },
      noPendingRequests: "لا توجد طلبات معلقة",
      noAcceptedRequests: "لا توجد طلبات مقبولة",
      receivedRequestsTitle: "طلبات الانضمام",
      acceptRequest: "قبول هذا الطلب",
      acceptRequestMsg: "هل ترغب في قبول هذا الطلب؟",
      rejectRequest: "رفض هذا الطلب",
      rejectRequestMsg: "هل ترغب في رفض هذا الطلب؟",
      updateRequest: "تحديث الطلب",
      update: "تحديث",
      close: "غلق",
      closed: "مغلق",
      closeThisGroup: "إغلاق هذه المجموعة",
      closeMsg: "هل ترغب في إغلاق هذه المجموعة؟",
      createTender: "نشر مناقصة",
      note: "ملاحظة",
      createTenderNote: "يجب عليك إغلاق المجموعة لنشر المناقصة",
    }
  },
  stack: {
    webMenu: {
      home: "الرئيسية",
      createTender: "إنشاء مناقصة",
      inbox: "الرسائل",
      articles: "المقالات",
      help: "مساعدة"
    }
  },
  tabs: {
    home: "الرئيسية",
    inbox: "الرسائل",
    add: "اضافة",
    articles: "المقالات",
    help: "مساعدة"
  },
  misc: {
    currency: "دج",
    next: "التالي",
    cancel: "إالغاء",
    select: "اختر",
    reject: "رفض",
    publish: "نشر",
    submit: "إرسال",
    done: "اغلاق",
    total: "مجموع",
    complete: "انتهاء",
    city: "مدينة",
    branch: "فرع",
    search: "بحث",
    states: "الولايات",
    branches: "فروع",
    signIn: "تسجيل الدخول",
    signUp: "التسجيل",
    tryAgain: "حاول مرة أخرى",
    confirm: "تأكيد",
    particular: "خاص",
    professional: "محترف",
    serverError: "خطأ في الخادم",
    errorTryAgain: "كان هناك خطأ يرجى محاولة في وقت لاحق",
    Ok: "موافق",
    price: "السعر",
    filesToUpload: "الملفات المطلوب رفعها",
    to: "ل",
    SARLSafakat: "ش.ذ.م.م صفقات",
    edit: "تعديل",
    save: "تسجيل",
    update: "تحديث",
    balance: "الرصيد",
    withdraw: "سحب",
    reason: "السبب",
    darkMode: "الوضع الليلي",
    tenders: "المناقصات",
    add: "اضافة",
    send: "أرسل",
    optional: "(اختياري)",
    clickToUpload: "انقر لرفع الملفات",
    max: "حد أقصى",
    points: "نقطة",
    yes: "نعم",
    no: "لا",
    deletedUser: "[مستخدم محذوف]",
    status: "الحالة"
  },
  validation: {
    states: "حدد حالة واحدة (1) على الأقل",
    state: "المدينة ضرورية",
    branches: "الفرع ضروري",
    branches2: "اختر فرعا واحدا (1) على الأقل",
    commercialRegisterFront: "الصفحة الأولى ضرورية",
    commercialRegisterBack: "الصفحة الخلفية ضرورية",
    role: "دور الشركة قصير جدا",
    companyName: "اسم الشركة قصير جدا",
    nameRequired: "الاسم ضروري",
    nameShort: "الاسم قصير جدا",
    invalidEmail: "البريد الإلكتروني غير صالح",
    emailTaken: "يتم أخذ هذا البريد الإلكتروني",
    oldPass: "كلمة المرور القديمة مطلوبة",
    newPass: "كلمة مرور جديدة ضرورية",
    newPass2: "كلمة المرور قصيرة جدا",
    startupLabel: "علامة 'شركة ناشئة' مطلوبة"
  }
}
