import React, { useState } from "react";

import {
  ScrollView, View, Image, Text, Button, Center,
  Box, FormControl, Input, Heading, Icon, Modal,
  useColorModeValue, useTheme, Spinner
} from "native-base";

import imgSignUp from '../../assets/sign-up.jpeg';
import imgLogo from "../../assets/logo_seconday.png";
import {Ionicons} from "@expo/vector-icons";
import {createMaterialTopTabNavigator} from "@react-navigation/material-top-tabs";
import apiClient from "../../utils/apiClient";
import {localInfo, translate} from "../../utils/Translate";
import {Platform} from "react-native";
import TermsModal from "../../components/TermsModal";
import PrivacyModal from "../../components/PrivacyModal";
import CountryStatesSelectList from "../../components/CountryStatesSelectList";

const isWebPlatform = Platform.OS === 'web';

export default function SignUp({ navigation }) {

  const Tab = createMaterialTopTabNavigator();
  const [showTerms, setShowTerms] = React.useState(false);
  const [showPrivacy, setShowPrivacy] = React.useState(false);

  const { colors } = useTheme();

  const screenOptions = {
    swipeEnabled: false,
    tabBarLabelStyle: { fontSize: 16, fontWeight: "bold" },
    tabBarItemStyle: {
      flexDirection: 'row'
    },
    tabBarActiveTintColor: "#fff",
    tabBarInactiveTintColor: "#000",
    tabBarStyle: {
      backgroundColor: "#ccc",
      height: 46,
      marginHorizontal: 20,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    tabBarIndicatorStyle: {
      backgroundColor: colors.appPrimary, height: 46,
      marginRight: 4,

      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }

  const Particular = ({ isProfessional = false }) => {

    const [errors, setErrors] = React.useState({});
    const [showServerError, setShowServerError] = React.useState(false);
    const [isSignUping, setIsSignUping] = React.useState(false);

    const [data, setData] = useState({
      ...(isProfessional && {
        company: {
          name: '',
          state_id: '',
          isOwner: true,
        }
      }),
      user: {
        isProfessional: isProfessional,
        full_name: '',
        email: '',
        company_role: null,
        phone_number: '',
        country_id: null,
        state_id: null
      }
    });

    let fullNameInputRef = React.useRef();
    let emailInputRef    = React.useRef();

    const handleFullNameInput = (text) => {

      let error = {...errors}
      delete error.name
      setErrors(error);

      return  setData({
        ...data,
        user: {
          ...data.user,
          full_name: text,
        }
      })
    }

    const handleEmailInput = (text) => {
      text = text.trim()

      let error = {...errors}
      delete error.email
      setErrors(error);

      return  setData({
        ...data,
        user: {
          ...data.user,
          email: text,
        }
      })
    }

    const handleCompanyNameInput = (text) => {

      let error = {...errors}
      delete error.companyName
      setErrors(error);

      return  setData({
        ...data,
        company: {
          ...data.company,
          name: text,
        }
      })
    }

    let onStateChanged = (stateID, countryID) => {
      setErrors({ ...errors,
        state: null
      });

      return  setData({
        ...data,
        user: {
          ...data.user,
          country_id: countryID,
          state_id: stateID,
        }
      })
    }

    const validate = () => {
      if (isProfessional){
        if (data.company.name < 2) {
          setErrors({ ...errors,
            companyName: translate('validation.companyName')
          });
          return false;
        }
      }
      if (data.user.full_name === undefined) {
        setErrors({ ...errors,
          name: translate('validation.nameRequired')
        });
        return false;
      } else if (data.user.full_name.length < 2) {
        setErrors({ ...errors,
          name: translate('validation.nameShort')
        });
        return false;
      } else if (!isProfessional && data.user.state_id === null) {
        setErrors({ ...errors,
          state: translate('validation.state')
        });
        return false;
      }
      if (!validateEmail(data.user.email)){
        setErrors({ ...errors,
          email: translate('validation.invalidEmail')
        });
        return false;
      }

      return true;
    };

    const validateEmail = (email) => {
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
      return reg.test(email)
    };

    const handleNextButton = () => {
      if (validate()){
        setIsSignUping(true)
        apiClient.post('check-email', { email: data.user.email })
          .then((res) => {
            setIsSignUping(false)
            if (res.data){
              setErrors({ ...errors,
                email: translate('validation.emailTaken')
              });
            } else {
              navigation.navigate('AddPhone', {userData: data})
            }
          }).catch((error) => {
          setIsSignUping(false)
            if (error.response.status === 429){
              setShowServerError(true)
            }
        })
      }
    }

    return (
      <View bg={useColorModeValue("white", "coolGray.800")}>
          <Center mt={3}>

            {
              !isProfessional ? null : (
                <FormControl mt={3} w="85%" isInvalid={'companyName' in errors}>
                  <Input
                    textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                    rounded={10} size="2xl" borderColor="gray.500" pl={4} py={[4, 4, 2]}
                    placeholder={translate('screens.auth.signUp.companyName')}
                    returnKeyType="next" blurOnSubmit={false}
                    onSubmitEditing={() => { fullNameInputRef.focus(); }}
                    onChangeText={handleCompanyNameInput}
                  />
                  {'companyName' in errors ? <FormControl.ErrorMessage>
                    {errors.companyName}
                  </FormControl.ErrorMessage> : null}
                </FormControl>
              )
            }
            <FormControl mt={3} w="85%" isInvalid={'name' in errors}>
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                rounded={10} size="2xl" borderColor="gray.500"
                pl={4} py={[4, 4, 2]} placeholder={translate('screens.auth.signUp.fullName')}
                ref={(input) => { fullNameInputRef = input }}
                returnKeyType="next" blurOnSubmit={false}
                onSubmitEditing={() => { emailInputRef.focus(); }}
                value={data.user.full_name}
                onChangeText={handleFullNameInput}
              />
              {'name' in errors ? <FormControl.ErrorMessage>
                {errors.name}
              </FormControl.ErrorMessage> : null}
            </FormControl>

            {
              isProfessional ? null : (
                <FormControl mt={3} w="85%" isInvalid={'state' in errors}>
                  <CountryStatesSelectList
                    selectText={translate('screens.auth.interests.selectCountryState')}
                    modalHeaderText={translate('screens.auth.interests.selectCountryState')}
                    doneText={translate('misc.done')}
                    cancelText={translate('misc.cancel')}
                    isMultiSelect={false}
                    onDone={onStateChanged}
                  />
                  {
                    'state' in errors ? (
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                        {errors.state}
                      </Text>
                    ) : null
                  }
                </FormControl>
              )
            }

            <FormControl mt={3} w="85%" isInvalid={'email' in errors}>
              <Input
                textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                rounded={10} size="2xl"
                autoCapitalize="none" returnKeyType="go"
                borderColor="gray.500" pl={4} py={[4, 4, 2]} placeholder={translate('screens.auth.signIn.email')}
                ref={(input) => { emailInputRef = input }}
                onSubmitEditing={handleNextButton}
                blurOnSubmit={false}
                onChangeText={handleEmailInput}
              />
              {'email' in errors ? <FormControl.ErrorMessage>
                {errors.email}
              </FormControl.ErrorMessage> : null}
              <FormControl.HelperText>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}>
                  {translate('screens.auth.signUp.willSendPass')}
                </Text>
              </FormControl.HelperText>
            </FormControl>
          </Center>

          <Center mt={7}>
            <Button
              w="85%"
              onPress={ handleNextButton }
              size="lg"
              color="appPrimary"
              borderRadius={8}
            >
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                {
                  isSignUping ? (
                    <Spinner size="sm" color="white" accessibilityLabel="Loading Sign in" />
                  ) : translate('misc.next')
                }
              </Text>
            </Button>
          </Center>

          {/* Error Modal */}
          {/*<Center>*/}
          {/*  <Modal size={["xl", "xl", "md"]} isOpen={showServerError} onClose={() => setShowServerError(false)}>*/}
          {/*    <Modal.Content>*/}
          {/*      <Modal.CloseButton />*/}
          {/*      <Modal.Header>*/}
          {/*        {translate('misc.signIn')}*/}
          {/*      </Modal.Header>*/}
          {/*      <Modal.Body>*/}

          {/*        <View>*/}
          {/*          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={17}>*/}
          {/*            {translate('misc.errorTryAgain')}*/}
          {/*          </Text>*/}
          {/*          <View mt={4}/>*/}
          {/*          <Button*/}
          {/*            w="100%"*/}
          {/*            onPress={ () => {*/}
          {/*              setShowServerError(false)*/}
          {/*            } }*/}
          {/*            size="lg"*/}
          {/*            color="appPrimary"*/}
          {/*            borderRadius={8}*/}
          {/*          >*/}
          {/*            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">*/}
          {/*              {translate('misc.Ok')}*/}
          {/*            </Text>*/}
          {/*          </Button>*/}
          {/*        </View>*/}

          {/*      </Modal.Body>*/}
          {/*    </Modal.Content>*/}
          {/*  </Modal>*/}
          {/*</Center>*/}

      </View>
    )
  }

  function getBoxWith(){
    if (Platform.OS === 'web') return [0, 0, 48, 96]
    return 0
  }

  return (
      <View>
        <ScrollView bg={useColorModeValue("white", "coolGray.800")}>
          <Box mt="-16" width="100%" height="348" bg="primary.600" >
            <Center>
              <Image w={[500, 700, 1400]} h={400} position="absolute" source={imgSignUp} resizeMode="cover" alt="imgSignUp" />
              <View w={[500, 700, 1400]} h={400} mt={24} opacity={.7} bg="primary.200" position="absolute" ></View>
              <Image mt={[0, -2, -8]} alt="imgLogo" size={[300, 300, 250]} resizeMode={"contain"} source={imgLogo} />
            </Center>
          </Box>

          <Center mt={[-32,-32, -48]} textAlign="center">
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize="32" fontWeight="bold">{translate('screens.auth.signIn.easyTendersTool')} </Text>
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="primary.50" fontSize="lg" fontWeight="bold">
              {translate('screens.auth.index.heading')}
            </Text>
          </Center>

          <Box
            px={getBoxWith()}
            mt={5} roundedTop="20" bg={useColorModeValue("white", "coolGray.800")}
          >

            <View ml={8} mr={2}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt="6" fontSize="lg" fontWeight="bold">
                {translate('screens.auth.signUp.registration')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mt="1" mb={2} fontSize="md" fontWeight={500}>
                {translate('screens.auth.signUp.accountType')}:
              </Text>
            </View>

            <View h="400">
              <Tab.Navigator screenOptions={screenOptions} style={{ direction: localInfo.isRTL && Platform.OS !== "web" ? 'rtl' : "ltr" }}>
                <Tab.Screen
                  name={translate('misc.particular')}
                  children={() => <Particular/>}
                  options={{
                    tabBarIcon: ({color }) => <Icon as={<Ionicons name="person"/>} color={color} size={5} />,
                  }} />
                <Tab.Screen
                  name={translate('misc.professional')}
                  children={() => <Particular isProfessional={true} />}
                  options={{
                    tabBarIcon: ({color }) => <Icon as={<Ionicons name="briefcase"/>} color={color} size={5} />,
                  }} />
              </Tab.Navigator>
            </View>

            <Center mt={1}>

              <Heading mt={4} mb={7} size="md">
                {translate('screens.auth.signUp.alreadyHaveAccount')}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appPrimary" onPress={() => navigation.navigate('Sign-in') }> {translate('misc.signIn')}</Text>
              </Heading>

              <Text fontSize={17} textAlign="center" mt="3" fontWeight="medium" w="85%">
                {translate('screens.auth.index.note')}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} onPress={() => setShowTerms(true)} color="appPrimary">
                  {` ${translate('screens.auth.index.termsAndCondition')} `}
                </Text>
                {translate('screens.auth.index.andOur')}{" "}
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} onPress={() => setShowPrivacy(true)} color="appPrimary">
                  {translate('screens.auth.index.privacyPolicy')}
                </Text>
              </Text>

            </Center>

            <TermsModal isOpen={showTerms} onClose={() => setShowTerms(false)}/>
            <PrivacyModal isOpen={showPrivacy} onClose={() => setShowPrivacy(false)}/>

            <View mt={20}/>
          </Box>
        </ScrollView>
      </View>
  );
}
