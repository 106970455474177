import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import * as Linking from 'expo-linking';
import DrawerNavigation from "./Drawer";

import SignUp from "../screens/auth/SignUp";
import SignIn from "../screens/auth/SignIn";
import AddPhone from "../screens/auth/steps/AddPhone";
import VerifyPhone from "../screens/auth/steps/VerifyPhone";
import CompanyInfo from "../screens/auth/steps/CompanyInfo";
import UnderReview from "../screens/auth/UnderReview";
import InterestedBranches from "../screens/auth/steps/InterestedBranches";
import EmailPage from "../screens/forgetPassword/EmailPage";
import ResetPage from "../screens/forgetPassword/ResetPage";
import apiClient from "../utils/apiClient";
import TokenManager from "../utils/TokenManager";
import AuthUserManager from "../utils/AuthUserManager";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import { AuthContext } from "../utils/context/auth";
import {Text, Center} from 'native-base';
import SuccessPage from "../screens/forgetPassword/SuccessPage";
import UserKeyManager from "../utils/UserKeyManager";
import {UNIVERSAL_LINK_PREFIX} from "../environment"

const prefix = Linking.createURL('/');

const appTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'rgb(255, 255, 255)',
    card: 'rgb(255, 255, 255)',
    background: 'rgb(255, 255, 255)'
  }
};

export default function Navigation() {
  const Stack = createNativeStackNavigator();

  const config = {
    screens: {
      app: {
        initialRouteName: 'tenders', // the homepage in Stack navigation
        screens: {
          Tender: 'app/tender/:tenderId',
        }
      },
      GroupBuying: {
        initialRouteName: 'Groups', // the homepage in Stack navigation
        screens: {
          Group: 'GroupBuying/Group/:groupId',
        }
      }
    },
  };

  const linking = {
    prefixes: [prefix, UNIVERSAL_LINK_PREFIX],
    config
  };

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isLoading, setIsLoading]           = useState(true)
  const [isCompany, setIsCompany]           = useState(null)

  const initUserKey = async () => {
    const hasPKey = await UserKeyManager.hasPrivateKey();
    if (!hasPKey) {
      await UserKeyManager.storePrivateKey()
    }
  }

  const authContext = React.useMemo(() => ({
    signIn: async(userData) => {
      return new Promise(async (resolve, reject) => {
        apiClient.post('users/login', userData).then( async (response) => {

          let token = response.data.access_token
          if (token){
            await TokenManager.store(token);
            let response = await fetchUser()

            // Check and add user's device private key
            await initUserKey();

            let user = response.data

            if (user.status.name === 'pending'){
              await TokenManager.remove();
              reject('in-pending')

            } else if (isAdmin(user.all_roles)){
              await TokenManager.remove();
              reject('admin')
            } else if (user.status.name === 'activated'){
              let isCompanyOrUser = !!user.company_id;

              await AuthUserManager.store(user)
              setIsUserLoggedIn(true);
              setIsCompany(isCompanyOrUser);
              resolve(true);

            } else if (user.status.name === 'deactivated'){
              await TokenManager.remove();
              reject('deactivated')
            }
          }
        }).catch(error => {
          reject(error.response.data);
        })
      })
    },
    signUp: (userData) => {

      return new Promise((resolve, reject) => {
        apiClient.post('users/register', userData, {isFormData: true}).then(response => {
          if (response.status === 201){
            resolve(true)
          }
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    refreshData: async() => {
      let user = await fetchUser()
      await AuthUserManager.store(user.data)
    },
    checkToken: async () => {
      const response = await apiClient.get('check-token').catch(e => console.log(e.response.data))
      if (!response || !response.data) return false;
      return response.data.valid;
    },
    signOut: async() => {
      await TokenManager.remove();
      await AuthUserManager.remove()
      setIsUserLoggedIn(false);
    },
    authUser: async () => {
      return await AuthUserManager.get()
    },
    hasPermissionTo: async (permissions) => {
      const user = await AuthUserManager.get()

      let index = user.permissions.findIndex(e => e.name === 'owner' || e.name === permissions)
      return index !== -1;
    },
    isAuthenticated: isUserLoggedIn,
    isCompany: isCompany
  }), [isUserLoggedIn, isCompany]);

  useEffect(() => {
    (async() => {

      const userToken = await TokenManager.get();
      const auth      = await AuthUserManager.get()

      if (!!auth && auth.company_id) {
        setIsCompany(true)
      }
      if (!!userToken){
        const isValidToken = await authContext.checkToken()

        if (isValidToken){
          await authContext.refreshData()
          setIsUserLoggedIn(true);
        } else {
          await TokenManager.remove();
          await AuthUserManager.remove();
          setIsUserLoggedIn(false);
        }

      }
      setIsLoading(false);
    })()
  }, []);

  const LoadingScreen = () => {
    return (
      <Center>
        <Text></Text>
      </Center>
    )
  }

  function isAdmin(roles){
    return roles.includes('admin')
  }

  async function fetchUser(){
    return await apiClient.get('users')
  }

  return (
    <NavigationContainer theme={appTheme} linking={linking} fallback={<Text>Loading...</Text>}>
      <AuthContext.Provider value={authContext}>
        {
          isLoading ? (
            <LoadingScreen/>
          ) : (
            <>
              {
                !isUserLoggedIn && !isLoading ? (
                  <Stack.Navigator>
                    <Stack.Screen name="Sign-in" component={SignIn} options={{ headerShown: false }} />
                    <Stack.Screen name="Sign-up" component={SignUp} options={{ headerShown: false }} />
                    <Stack.Screen name="AddPhone" component={AddPhone} options={{ headerShown: false }} />
                    <Stack.Screen name="VerifyPhone" component={VerifyPhone} options={{ headerShown: false }} />
                    <Stack.Screen name="CompanyInfo" component={CompanyInfo} options={{ headerShown: false }} />
                    <Stack.Screen name="UnderReview" component={UnderReview} options={{ headerShown: false }} />
                    <Stack.Screen name="InterestedBranches" component={InterestedBranches} options={{ headerShown: false }} />
                    <Stack.Screen name="EmailPage" component={EmailPage} options={{ headerShown: false }} />
                    <Stack.Screen name="ResetPage" component={ResetPage} options={{ headerShown: false }} />
                    <Stack.Screen name="SuccessPage" component={SuccessPage} options={{ headerShown: false }} />
                  </Stack.Navigator>
                ) : (
                  <DrawerNavigation />
                )
              }
            </>
          )
        }
      </AuthContext.Provider>
    </NavigationContainer>
  );
}
