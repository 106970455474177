import React, {useEffect} from 'react';
import { StyleSheet, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';
import {localInfo, translate} from "../../../utils/Translate";

import firebase from "../../../utils/firebase";

import {
  Pressable, View, Image, Text, Button, Center, Box, useColorModeValue,
} from "native-base";

export default function AddPhone({ route, navigation }) {

  const { userData } = route.params;
  const [userPhone, setUserPhone] = React.useState('')
  const [recaptchaSolved, setRecaptchaSolved] = React.useState(false)

  const recaptchaVerifier = React.useRef({})

  useEffect(() => {
    if (Platform.OS !== 'web'){
      // don't use reCAPTCHA on mobile devices
      setRecaptchaSolved(true);
    }

    if (Platform.OS === 'web'){
      recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        'size': 'normal',
        'callback': (response) => {
          // reCAPTCHA solved
          setRecaptchaSolved(true);
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          console.log("reCAPTCHA expired")
        }
      });
      recaptchaVerifier.current.render()
    }
  }, []);

  return (
      <Box
        px={[0, 0, 32, 64]}
        flex={1} flexDirection="column" justifyContent="space-between"
        bg={useColorModeValue("white", "coolGray.800")}
      >
        <View mt={12}>
          <Pressable
            m={5}
            onPress={() => navigation.goBack()}>
            <Image
              alt="back icon"
              source={require('../../../assets/icons/back_arrow.png')}
            />
          </Pressable>

          <View mt={12}>

            {
              userData.user.isProfessional ? (
                <Box ml={4} mr={10}>
                  <View alignItems='flex-end' flexDirection="row">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">1/4 </Text>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={1}>
                      {translate('misc.complete')}
                    </Text>
                  </View>

                  <View mt={2} flexDirection="row">
                    <View m={1} h={1} w="25%" bg="appPrimary"></View>
                    <View m={1} h={1} w="25%" bg="appSecondary"></View>
                    <View m={1} h={1} w="25%" bg="appSecondary"></View>
                    <View m={1} h={1} w="25%" bg="appSecondary"></View>
                  </View>
                </Box>
              ) : (
                <Box ml={4} mr={10}>
                  <View alignItems='flex-end' flexDirection="row">
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="24">1/3 </Text>
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={1}>
                      {translate('misc.complete')}
                    </Text>
                  </View>

                  <View mt={2} flexDirection="row">
                    <View m={1} h={1} w="33%" bg="appPrimary"></View>
                    <View m={1} h={1} w="33%" bg="appSecondary"></View>
                    <View m={1} h={1} w="33%" bg="appSecondary"></View>
                  </View>
                </Box>
              )
            }

            <View ml={4} mt={8}>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="2xl" fontWeight={700}>
                {translate('screens.auth.addPhone.phoneNumber')}
              </Text>
              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="xl" fontWeight={200}>
                {translate('screens.auth.addPhone.note')}
              </Text>
            </View>

            <View style={{ direction: 'ltr' }}>
              <PhoneInput
                // ref={phoneInput}
                // defaultValue={phoneNumber}
                defaultCode="DZ"
                layout="first"
                withShadow
                autoFocus
                containerStyle={styles.phoneNumberStyle}
                textContainerStyle={{ paddingVertical: 0 }}
                onChangeFormattedText={text => {
                  userData.user.phone_number = text
                  setUserPhone(text)
                }}
              />
            </View>

          </View>
        </View>

        {
          Platform.OS === 'web' ? (
            <Center mt={8}>
              <View nativeID={'recaptcha-container'}></View>
            </Center>
          ) : null
        }

        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <Center mb={10}>
              <Button
                w="85%"
                isDisabled={userPhone.length < 8 || !recaptchaSolved}
                onPress={ () => navigation.navigate('VerifyPhone', {
                  userData, recaptchaVerifier: recaptchaVerifier.current
                }) }
                size="lg"
                color="appPrimary"
                borderRadius={8}
              >
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize="lg" color="primary.50" fontWeight="700">
                  {translate('misc.next')}
                </Text>
              </Button>
            </Center>
          </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
      </Box>
    );
}

const styles = StyleSheet.create({

  phoneNumberStyle: {
    width: '100%',
    height: 50,
    backgroundColor: 'white',
    marginTop: 20
  },
});
