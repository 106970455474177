import React, {useEffect, useState} from 'react';
import {MaterialCommunityIcons, Feather, Ionicons} from "@expo/vector-icons";
import * as WebBrowser from 'expo-web-browser';

import TenderModel from "../models/TenderModel";
import apiClient from "../utils/apiClient";
import {Provider as PaperProvider} from "react-native-paper";
import {DatePickerModal} from "react-native-paper-dates";
import datePickerTheme from "../theme/datePickerTheme";
import TableConverter from "../utils/TableConverter";

import { Table, TableWrapper, Row, Rows, Cell, Col } from 'react-native-table-component-2';
import {
  View, Text, Icon, Center, ScrollView, Pressable, Divider,
  Input, Button, Modal, FormControl, HStack, useColorMode, Spinner, useTheme
} from "native-base";
import {Platform, StyleSheet, TouchableOpacity} from "react-native";
import {AuthContext} from "../utils/context/auth";
import AuthUserManager from "../utils/AuthUserManager";
import TokenManager from "../utils/TokenManager";
import {formatBytes} from "../utils/StringTool";
import {localInfo, translate} from "../utils/Translate";
import TenderSkeleton from "../components/skeleton/TenderSkeleton";
import * as DocumentPicker from "expo-document-picker";
import TimeFormatter from "../utils/TimeFormatter";
import SubscriptionExpiredModal from "../components/SubscriptionExpiredModal";

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={28} fontWeight={500}>{title}</Text>
  </View>
}

const SectionTitle = ({ title, suffix }) => {
  return <View>
    <HStack alignContent="center">
      <Text textAlign="left" fontSize={28} fontWeight={500}>
        {title}
      </Text>
      {
        !!suffix ? <Text textAlign="left" ml={2} mt={2} fontSize={18} fontWeight={400}>
          ({suffix})
        </Text> : null
      }
    </HStack>
    <View height={1} backgroundColor="appPrimary" w={60}/>

  </View>
}

const widthArr = [150, 150, 150, 150, 150]

export default ({ route, navigation }) => {

  const { tenderData, previewData } = route.params;

  const {colorMode} = useColorMode();
  const { colors }  = useTheme();

  const isDark = colorMode === 'dark';


  const [showConfirmOfferModal, setShowConfirmOfferModal] = useState(false);
  const [isOffering, setIsOffering] = useState(false);
  const [tender, setTender] = useState(TenderModel[0]);
  const [pointsTableData, setPointsTableData] = useState([]);
  const [priceTableData, setPriceTableData] = useState([]);
  const [totalTableData, setTotalTableData] = useState([ [0], [0], [0] ]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [tenderRequiredDocs, setTenderRequiredDocs] = useState([]);
  const [inputErrors, setInputErrors] = React.useState({});
  const pointsTableIsValid = React.useRef({});

  const pointsTableHead = [
    translate('screens.tender.pointsTableHeader.item'),
    translate('screens.tender.pointsTableHeader.requirement'),
    translate('screens.tender.pointsTableHeader.points'),
    translate('screens.tender.pointsTableHeader.offer'),
    translate('screens.tender.pointsTableHeader.total')
  ]

  const priceTableHead = [
    translate('screens.tender.priceTableHeader.item'),
    translate('screens.tender.priceTableHeader.unit'),
    translate('screens.tender.priceTableHeader.quantity'),
    translate('screens.tender.priceTableHeader.price'),
    translate('screens.tender.priceTableHeader.total')
  ]

  const [totalTableTitles, setTotalTableTitles] = useState([
    translate('screens.tender.totalTableTitles.TOTAL_HT'),
    translate('screens.tender.totalTableTitles.TVA'),
    translate('screens.tender.totalTableTitles.TOTAL_TTC')
  ]);

  // Offer state
  const [date, setDate] = useState();
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [hasReviewSteps, setHasReviewSteps] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [offerData, setOfferData] = useState({
    offer: {
      bid_amount : null,
      delivery_date : null,
      reason : null
    },
    offerPointsTables: [],
    offerPriceTables: []
  });

  useEffect(() => {

    let canOffer = !previewData || !tender.is_owner || !tender.has_participate;
    setIsOffering(canOffer)

    getTender()
  }, []);

  const validate = () => {
    // Validate offer max points input
    for (const index in pointsTableIsValid.current) {
      if (!pointsTableIsValid.current[index]){
        return false
      }
    }
    // Validate offer required documents
    for (const [index, doc] of tenderRequiredDocs.entries()){
      if (doc.is_required && !doc.file){
        setInputErrors({ ...inputErrors,
          [`docRequired${index}`]: translate('screens.publishTender.requiredDocuments.requiredDocument')
        });

        return false;
      }
    }

    return true;
  };

  const pickDocument = async (index) => {
    let file = await DocumentPicker.getDocumentAsync({
      multiple: false
    });

    if (!!file.type && file.type === 'cancel'){
      return;
    }

    if (`docRequired${index}` in inputErrors) {
      let errors = {...inputErrors}
      delete errors[`docRequired${index}`]
      setInputErrors(errors);
    }

    if(Platform.OS === 'web'){
      const files = file.output;

      let requiredDocs = [...tenderRequiredDocs]

      if (!!requiredDocs[index].file){
        delete requiredDocs[index].file
      }

      requiredDocs[index].file = files[0]
      setTenderRequiredDocs(requiredDocs)

      return;
    }

    let requiredDocs = [...tenderRequiredDocs]

    if (!!requiredDocs[index].file){
      delete requiredDocs[index].file
    }

    requiredDocs[index].file = {
      uri: file.assets[0].uri,
      type: file.assets[0].mimeType,
      name: file.assets[0].name,
      size: file.assets[0].size
    }
    setTenderRequiredDocs(requiredDocs)
  };

  function getTender(){

    if (!!previewData) {
      let preview = {...previewData};

      let previewTender = {...preview.tender}
      delete preview.tender;
      let previewTenderData = {...previewTender, ...preview}

      setTVA(previewTenderData.tva)
      setTender(previewTenderData)
      setHasReviewSteps(!!previewData.reviewSteps.length)

      // Set preview tables
      if (preview.pointTablePreview.length > 0){
        let pointTablePreview = preview.pointTablePreview.filter((item, index) => index !== preview.pointTablePreview.length -1 )
        setPointsTableData(pointTablePreview);
      }
      if (preview.priceTablePreview.length > 0){
        let priceTablePreview = preview.priceTablePreview;

        if (preview.priceTablePreview[preview.priceTablePreview.length -1][0] === ""){
          priceTablePreview = preview.priceTablePreview.filter((item, index) => index !== preview.priceTablePreview.length -1 )
        }

        setPriceTableData(priceTablePreview);
      }

      return;
    }

    setTender(tenderData)
    setTVA(tenderData.tva)
    setTenderRequiredDocs(tenderData.required_docs)
    getTenderPointsTable(tenderData.pointtables)
    getTenderPriceTable(tenderData.pricetables)
  }

  function getTenderPointsTable(pointTables){
    let tableArr = TableConverter.pointsToArray(pointTables)
    setPointsTableData(tableArr);
  }

  function getTenderPriceTable(priceTables){
    let tableArr = TableConverter.priceToArray(priceTables)
    setPriceTableData(tableArr);
  }

  const handleSubmitOffer = () => {

    if (!validate()) return;

    if (tender.bid_initial_price !== null){
      setShowConfirmOfferModal(true)
      return;
    }
    if (pointsTableData.length === 0){
      setShowConfirmOfferModal(true)
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: tender.tenderable.avatar,
      tenderId: tender.id,
      isPaidTender: false,
      price: tender.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: tender.pointtables,
        offerPriceTables: tender.pricetables,
      },
      tenderRequiredDocs
    })
  }

  const submitOffer = () => {
    setIsPublishing(true)

    const data = new FormData()
    data.append('data', JSON.stringify({
      ...offerData,
      offerPointsTables: tender.pointtables,
      offerPriceTables: tender.pricetables,
      isPaid: 0
    }))

    for (let n = 0; n < tenderRequiredDocs.length; n++){
      if (!!tenderRequiredDocs[n].file){
        data.append('docs[]', tenderRequiredDocs[n].file)
        data.append('docsNames[]', tenderRequiredDocs[n].name)
      }
    }

    apiClient.post(`tenders/${tender.id}/offers`, data, {isFormData: true}).then(() => {
      setShowConfirmOfferModal(false);
      setIsPublishing(false)

      navigation.navigate('OfferSent')
    }).catch((error) => console.log(error.response.data))
  }

  function saveDownloadHistory(attachmentId){
    return apiClient.post(`downloads`, {attachmentId})
  }

  const handleBid = (value) => {
    let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        bid_amount: value.replace(regex, '')
      }
    })
  }

  const onDismissSingle = () => {
    setOpenDatePicker(false);
  };

  const onConfirmSingle = (params) => {
    setOpenDatePicker(false);
    setDate(params.date);

    setFormattedDate(TimeFormatter.getFormattedDate(params.date));

    setOfferData({
      ...offerData,
      offer: {
        ...offerData.offer,
        delivery_date: getFormattedDate(params.date)
      }
    })
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const OfferInput = (index, type) => {
    if (!!tender.pointtables[index] === false) {
      return <></>;
    }

    function checkIsValid (){
      if (!!tender.pointtables[index].max_points === false){
        return false;
      } else if (tender.pointtables[index].total > tender.pointtables[index].max_points){
        pointsTableIsValid.current[index] = false
        return true;
      } else {
        pointsTableIsValid.current[index] = true
        return false;
      }
    }

    return <View>
      <FormControl isInvalid={checkIsValid()}>
        <Input keyboardType="numeric" onChangeText={(value) => {
          let regex = localInfo.local === 'ar' ? /[^\u0660-\u0669]/g : /[^0-9]/g;

          let offerValue = value.replace(regex, '');

          if (type === 'point'){
            tender.pointtables[index].offer = offerValue;
            tender.pointtables[index].total = offerValue * tender.pointtables[index].points;

            let updatePointsTableData = [...pointsTableData];
            updatePointsTableData[index][4] = tender.pointtables[index].total;
            setPointsTableData(updatePointsTableData);
          }
          if (type === 'price'){
            tender.pricetables[index].price = offerValue;
            tender.pricetables[index].total = offerValue * tender.pricetables[index].quantity;

            let updatePriceTableData = [...priceTableData];
            updatePriceTableData[index][4] = tender.pricetables[index].total;
            setPriceTableData(updatePriceTableData);
            calculatePriceTable()
          }
        }} textAlign='center' size="2xl" placeholder="- -"/>
        <FormControl.ErrorMessage>
          {`${translate('misc.max')} (${tender.pointtables[index].max_points}) ${translate('misc.points')}`}
        </FormControl.ErrorMessage>
      </FormControl>
    </View>
  };

  function setTVA(tva){
    let updateTVA = [...totalTableTitles];
    updateTVA[1] = `TVA ${tva}%`;
    setTotalTableTitles(updateTVA);
  }

  function calculatePriceTable(){

    let totalHT  = tender.pricetables.reduce((total, item) => item.total + total,0);
    let TVA      = totalHT * tender.tva/100;
    let totalTTC = totalHT + TVA;

    let updatePriceTotal = [...totalTableData];
    updatePriceTotal[0][0] = formatter.format(totalHT);
    updatePriceTotal[1][0] = formatter.format(TVA);
    updatePriceTotal[2][0] = formatter.format(totalTTC);
    setTotalTableData(updatePriceTotal);
  }

  let formatter = new Intl.NumberFormat(localInfo.local, {
    style: 'currency',
    currency: !!tender.currency_id ? tender.currency.code.en : 'DZD',
  });


  const handleSubmitPaidOffer = () => {

    if (pointsTableData.length === 0){
      navigation.navigate("PaymentMethods", {
        price: tender.participation_cost,
        tenderId: tender.id,
        offerData: {
          ...offerData,
          offerPointsTables: tender.pointtables,
          offerPriceTables: tender.pricetables,
        }
      })
      return;
    }

    navigation.navigate('OfferReview', {
      avatar: tender.tenderable.avatar,
      tenderId: tender.id,
      isPaidTender: true,
      price: tender.participation_cost,
      offerData: {
        ...offerData,
        offerPointsTables: tender.pointtables,
        offerPriceTables: tender.pricetables,
      }
    })
  }

  if (!tender.title){
    return (
      <TenderSkeleton/>
    )
  }

  return (
    <View
      bg={isDark ? "coolGray.800" : "white"}
      px={3} h="100%"
    >
      {
        !previewData ? null : <View bg="appSecondary" py={1} px={10}>
          <Center>
            <View flexDirection="row">
              <Text textAlign="center" fontWeight={500} fontSize={24} color="primary.50">
                {translate('screens.tender.previewText')}
              </Text>
              <Center ml={10}>
                <Icon color="primary.50" as={<Feather name="eye" />} size={7} />
              </Center>
            </View>
          </Center>
        </View>
      }

      <ScrollView>

        {/* Check user subscription */}
        <SubscriptionExpiredModal featureToAccess={"participate-tender"}/>

        <View my={2}/>

        {
          tender.attachments.length > 0 ? (
            <View mt={4} mb={6}>
              <SectionTitle title={translate('screens.tender.documents')} />

              {
                tender.attachments.map((file, index) => (
                  <View key={index} mt={2}>
                    <TouchableOpacity
                      onPress={async () => {
                        if (!!previewData) return
                        await saveDownloadHistory(file.id)
                        await WebBrowser.openBrowserAsync(encodeURI(file.path));
                      }}
                    >
                      <HStack alignItems="center">
                        <Icon as={MaterialCommunityIcons} name="folder-download-outline" size="md" color="appPrimary" />
                        <View mx={1}/>
                        <Text textAlign="left" fontSize={16} color="appPrimary">
                          {`${file.name} - ${formatBytes(file.size)}`}
                        </Text>
                      </HStack>
                    </TouchableOpacity>
                  </View>
                ))
              }
            </View>
          ) : null
        }
        {
          tender.bid_initial_price === null ? null : <FormControl mt={4}>
            <Input
              textAlign="left"
              placeholder={`${translate('screens.tender.bid')} (${tender.currency.code[localInfo.local]})`} rounded={10}
              size="xl" borderColor="gray.500" pl={4} py={3}
              w={["100%", "100%", "42%"]}
              keyboardType="number-pad"
              isDisabled={!!previewData}
              value={offerData.offer.bid_amount}
              onChangeText={handleBid}
            />
            <FormControl.HelperText>
              <Text textAlign="left" ml={2} mt={-1} fontSize={18}>
                {translate('screens.tender.startingPrice')}: {tender.bid_initial_price} {tender.currency.code[localInfo.local]}
              </Text>
            </FormControl.HelperText>
          </FormControl>
        }
        {
          pointsTableData.length === 0 ? null : <View mt={8}>
            <SectionTitle title={translate('screens.tender.pointsTable')}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={pointsTableHead} style={styles.head} textStyle={styles.headText}/>

                    {pointsTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          cellIndex !== 5 ? <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={
                              (cellIndex === 3 && isOffering && !previewData && !tender.is_owner && !tender.has_participate)
                                ? OfferInput(index, 'point') : cellData
                            }
                            textStyle={styles.text}
                          /> : null
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>
          </View>
        }

        {
          priceTableData.length === 0 ? null : <View mt={8}>
            <SectionTitle title={translate('screens.tender.priceTable')} suffix={tender.currency.code[localInfo.local]}/>

            <View mt={5}>
              <ScrollView horizontal={true}>
                <View style={styles.container}>
                  <Table style={{}} borderStyle={{borderWidth: 2, borderColor: colors.appPrimary}}>
                    <Row widthArr={widthArr} data={priceTableHead} style={styles.head} textStyle={styles.headText}/>

                    {priceTableData.map((rowData, index) => (
                      <TableWrapper widthArr={widthArr} key={index} style={styles.row}>
                        {rowData.map((cellData, cellIndex) => (
                          <Cell
                            style={index === 2 ? {backgroundColor: '#fff'} : null}
                            width={150}
                            key={cellIndex}
                            data={(cellIndex === 3 && isOffering && !previewData && !tender.is_owner && !tender.has_participate) ? OfferInput(index, 'price') : cellData}
                            textStyle={styles.text}
                          />
                        ))}
                      </TableWrapper>
                    ))}

                  </Table>
                </View>
              </ScrollView>
            </View>

            <View my={4}>
              <SectionTitle title={translate('misc.total')} suffix={tender.currency.code[localInfo.local]}/>
            </View>

            <ScrollView horizontal={true}>
              <View style={styles.container}>
                <Table borderStyle={{ borderWidth: 1 }}>
                  <TableWrapper style={styles.row}>
                    <Col
                      style={styles.title}
                      textStyle={styles.text}
                      data={totalTableTitles}
                    />
                    <Rows data={totalTableData} widthArr={[170]} textStyle={styles.text} />
                  </TableWrapper>
                </Table>
              </View>
            </ScrollView>

          </View>
        }
        {
          tender.bid_initial_price !== null ? null : <View mt={8}>
            <SectionTitle title={translate('screens.tender.deliveryDate')} />

            <Pressable
              w={["100%", "100%", "42%"]}
              onPress={() => !!previewData || tender.is_owner || tender.has_participate
              || (!!tender.is_the_closing_date && tender.is_the_closing_date) ? null : setOpenDatePicker(true)}>
              <FormControl mt={4}>
                <Input
                  textAlign="left"
                  placeholder={translate('screens.tender.deliveryDate')}
                  value={formattedDate} isReadOnly rounded={10} size="xl"
                  pointerEvents="none" borderColor="gray.500" pl={4} py={3}
                />
                <FormControl.HelperText>
                  <Text textAlign="left" ml={2} mt={-1}>
                    {translate('screens.tender.deliveryDate')}
                  </Text>
                </FormControl.HelperText>
              </FormControl>
            </Pressable>

            <PaperProvider theme={datePickerTheme}>
              <DatePickerModal
                locale="en"
                mode="single"
                visible={openDatePicker}
                onDismiss={onDismissSingle}
                date={date}
                onConfirm={onConfirmSingle}
                validRange={{
                  startDate: new Date(),  // optional
                  endDate: new Date(2999, 1, 1),
                  disabledDates: [new Date()] // optional
                }}
              />
            </PaperProvider>
          </View>
        }

        {
          tenderRequiredDocs.length ? (
            <View mt={8}>
              <SectionTitle title={translate('screens.tender.requiredDocuments')} />
            </View>
          ) : null
        }

        {
          tenderRequiredDocs.map((doc, docIndex) => (
            <View>
              <FormControl mt={5} isInvalid={`docRequired${docIndex}` in inputErrors}>
                <Text fontSize={20} fontWeight={600}>
                  {doc.name}
                </Text>
                <Text fontSize={17} mb={1}>
                  {
                    !doc.is_required ? translate('misc.optional') : null
                  } {doc.note}
                </Text>
                <Button
                  variant="outline"
                  leftIcon={<Icon as={Ionicons} name="cloud-upload-outline" size="sm" />}
                  onPress={ async () => {
                    await pickDocument(docIndex)
                  }}
                >
                  {translate('misc.clickToUpload')}
                </Button>

                {
                  `docRequired${docIndex}` in inputErrors ? (
                    <FormControl.ErrorMessage>
                      {inputErrors[`docRequired${docIndex}`]}
                    </FormControl.ErrorMessage>
                  ) : null
                }

              </FormControl>

              {
                !!doc.file ? (
                  <HStack alignItems="center">
                    <TouchableOpacity onPress={() => {
                      let requiredDocs = [...tenderRequiredDocs]
                      delete requiredDocs[docIndex].file
                      setTenderRequiredDocs(requiredDocs)
                    }}>
                      <Icon as={Ionicons} name="trash-bin-outline" size={5} color="appSecondary" />
                    </TouchableOpacity>
                    <View mx={1}/>
                    <Text textAlign="left" fontSize={17}>
                      {doc.file.name} - {formatBytes(doc.file.size)}
                    </Text>
                  </HStack>
                ) : null
              }


              <View mt={9} mb={3} mx="8">
                <Divider thickness="1" bg="appPrimary" />
              </View>
            </View>
          ))
        }

        {
          !!previewData || !tender.participation_cost || (tender.is_owner || tender.has_participate)
          || (!!tender.is_the_closing_date && tender.is_the_closing_date) ? null :
            <View mt={50}>
              <Button
                borderRadius={10}
                onPress={handleSubmitPaidOffer}
                isDisabled={!!tender.bid_initial_price === false && !!offerData.offer.delivery_date === false}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('misc.next')}
                </Text>
              </Button>
            </View>
        }
        {
          !!previewData ? <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.sendYourOffer')}
                </Text>
              </Button>
            </View>
            :
            null
        }
        {
          !!previewData || tender.is_owner || tender.has_participate
          || !!tender.participation_cost || (!!tender.is_the_closing_date
            || tender.is_closed && tender.is_the_closing_date) ? null :
            <View mt={50}>
              <Button
                borderRadius={10}
                onPress={handleSubmitOffer}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.sendYourOffer')}
                </Text>
              </Button>
            </View>
        }
        {
          (!!tender.is_the_closing_date && tender.is_the_closing_date) || tender.is_closed ? (
            <View mt={50}>
              <Button
                borderRadius={10}
                isDisabled={true}
                onPress={handleSubmitOffer}
              >
                <Text textAlign="left" fontSize={22} fontWeight={500} color="primary.50">
                  {translate('screens.tender.closed')}
                </Text>
              </Button>
            </View>
          ) : null
        }

        {/* Confirm offer Modal */}
        <Center>
          <Modal isOpen={showConfirmOfferModal} onClose={() => setShowConfirmOfferModal(false)}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('screens.tender.offerModal.headerText')}
              </Modal.Header>
              <Modal.Body>
                <Center>
                  <Text textAlign="left" fontWeight={600} fontSize={26}>
                    {translate('screens.tender.publishModal.areYouSure')}
                  </Text>
                  <Text mt={3} fontSize={20} textAlign="center">
                    {translate('screens.tender.offerModal.note')}
                  </Text>
                </Center>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowConfirmOfferModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                  <Button onPress={submitOffer}>
                    {
                      isPublishing ? (
                        <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                      ) : translate('misc.submit')
                    }
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

        <View mt={48} />

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: '#F6F6F6' },
  head: {  backgroundColor: '#262626' },
  text: { margin: 6, textAlign: 'center', fontSize: 17, fontWeight: 'bold' },
  wrapper: { flexDirection: 'row' },
  title: { flex: 1, backgroundColor: '#f6f8fa', width: 200 },
  headText: {
    color: 'white', paddingRight: 6, paddingLeft: 6,
    fontSize: 17, fontWeight: 'bold', textAlign: 'center'
  },
  row: { flexDirection: 'row', backgroundColor: '#F8F8F8' },
});
