import {Modal, Text} from "native-base";
import React from "react";

export const english = {
  screens: {
    auth: {
      signIn: {
        email: "Email address",
        password: "Password",
        haveAccount: "Don’t have an account ?",
        easyTendersTool: "Easy tenders tool",
        emailOrPassError: "Either email or password is wrong",
        accountSuspended: "This account has been suspended",
        adminAccount: "You can't use your admin account here"
      },
      signUp: {
        companyName: "Company Name",
        fullName: "Full Name",
        willSendPass: "We will send the password",
        alreadyHaveAccount: "Already have an account ?",
        registration: "Registration",
        accountType: "Account type"
      },
      index: {
        heading: "Find tenders and submit offers in one place",
        heading2: "Semi-decentralized system",
        CreateAccount: "Create an account",
        note: "by registering or sign in you accept the",
        termsAndCondition: "Terms & condition",
        andOur: "and our",
        privacyPolicy: "Privacy policy"
      },
      addPhone: {
        phoneNumber: "Phone number",
        note: "We will send you a verification code"
      },
      companyInfo: {
        CNRCInfo: "Company Info",
        uploadCommercialRegister: "Upload Commercial Register",
        frontPage: "Front page",
        backPage: "Back page",
        notOwner: "I’m not the owner",
        yourJobTitle: "Your job title",
        areYouStartup: "Are you a Startup?",
        yesStartup: "Yes, i'm Startup.",
        uploadLabel: "Upload Label",
        label: "Label",
      },
      interests: {
        selectInterests: "Select the branches and states that interest you",
        selectStates: "Select states",
        selectCountryStates: "Select country and state(s)",
        selectCountryState: "Select country and state",
        selectBranches: "Select branches",
        signUpModal: {
          headerTitle: "Creating your account",
          creatingText: "Creating your account, please wait...",
          accountCreated: "Your account has been created successfully.",
          passSent: "We have sent your account's password to your email"
        }
      },
      verifyPhone: {
        verifyPhone: "Verify Phone number",
        enterCode: "Enter code sent to your phone number",
        weSentCode: "We’ve sent a code to",
        invalidCode: "This code is invalid",
        captchaError: "Captcha error, please try again"
      },
      underReview: {
        accountUnderReview: "Your account is under review",
        reviewingAccount: "We’re reviewing your account",
        checkBack: "This usually takes less than 48 hours. Please check back soon.."
      }
    },
    home: {
      title: "Home",
      welcome: "Welcome",
      at: "at",
      latestTenders: "Your latest tenders",
      seeAll: "See all",
      historyStatistics: "History & Statistics",
      recentTenders: "Recent tenders",
      exploreTenders: "Explore tenders",
      articles: "Articles",
      companies: "Companies",
      otherTenders: "Other tenders"
    },
    inbox: {
      title: "Inbox",
      searchPlaceholder: "Search..",
      inboxEmpty: "You don't have any messages yet",
    },
    publishTender: {
      searchPlaceholder: "Search..",
      title: "Publish tender",
      noPermission: "Sorry you need permission from your company to do this action",
      info: {
        description: "Tender info",
        validation: {
          title: "Title is required",
          description: "Description is required",
          branch: "Branch is required",
          state: "State is required",
          language: "Please select a language",
          participation: "Please select a participation type",
          participationCost: "Please add the price"
        },
        placeholder: {
          location: "Location",
          title: "Title",
          description: "Description",
          language: "Language",
          participationType: "Participation type",
          cost: "Cost"
        },
        participationCostNote: "Please add the cost in Algerian dinar (DZD)",
        uploadFile: "Upload file (Optional)",
        clickToUpload: "Click to upload a specifications file",
        filesToUpload: "Files to upload",
        tenderLocation: "Tender location"
      },
      requiredDocuments:{
        title: "Required documents",
        modalHeader: "Add required document",
        description: "Here you can ask the offer owner to attach the required documents",
        document: "Document",
        name: "Name",
        add:{
          documentName: "Document name",
          documentNote: "Note",
          required: "Required"
        },
        requiredDocument: "This document is required"
      },
      criteria: {
        title: "Selecting Criteria",
        description: "Add from below what fits your tender, so the participants can fill it out",
        pointsTable: "Points table",
        addPointsTable: "Add a points table to your tender",
        priceTable: "Price Table",
        addPriceTable: "Add a price table to your tender",
        bid: "Bid",
        totalCost: "The total cost for this tender",
        closingDate: "The tender will be closed on this day",
        placeholder: {
          currency: "Currency",
          TVA: "TVA",
          initialPrice: "Initial price",
          pickDate: "Pick a closing date"
        },
        validation: {
          initPrice: "Initial Price is required",
          currency: "Currency is required"
        }
      },
      pointsTable: {
        title: "Create your points table",
        description: "Create your own points table so the participants can fill it",
        addItem: "Add an Item",
        addToTable: "Add to points table",
        validation: {
          itemName: "Item name is required",
          itemNumber: "Item number is required",
          itemPoint: "Item points is required"
        },
        placeholder: {
          itemName: "Item name",
          requirementNumber: "Required number",
          numberOfPoints: "Number of points for each item",
          maxPoints: "Max points"
        }
      },
      priceTable: {
        title: "Create your price table",
        description: "Create your own price table so the participants can fill it out",
        addItem: "Add an Item",
        addToTable: "Add to price table",
        validation: {
          itemName: "Item name is required",
          itemUnit: "Item unit is required",
          itemQuantity: "Item quantity is required"
        },
        placeholder: {
          itemName: "Item name",
          unit: "Unit",
          requiredQuantity: "Required quantity"
        }
      },
      reviewSteps: {
        title: "Follow-up steps",
        description: "Add the follow-up steps that the selected bidder must complete",
        stepNumber: "Step Number",
        stepReviewers: "Reviewers for this step",
        reviewers: "Reviewers",
        addStep: "Add a step",
        validation: {
          title: "Title is required",
          description: "Description is required",
          action: "Please select an action"
        },
        placeholder: {
          stepTitle: "Step title",
          description: "Description",
          action: "Action"
        },
        actions: {
          none: "None",
          text: "Text",
          files: "Files"
        }
      }
    },
    articles: {
      title: "Articles and tips",
      searchPlaceholder: "Search.."
    },
    help: {
      title: "Help and assistance",
      chat: "Chat with us",
      call: "Call line",
      facebookPage: "Facebook page",
      FAQ: "Frequently asked questions"
    },
    tenders: {
      userTendersTitle: "Your tenders",
      tendersTitle: "Tenders",
      Branches: "Branches",
      states: "States"
    },
    tender: {
      previewText: "This is what your tender looks like",
      share: "Share",
      profile: "Profile",
      message: "Message",
      reviewSteps: "Follow-up steps",
      receivedOffers: "Received offers",
      participationFees: "Participation fees",
      description: "Description",
      views: "Views",
      closingDate: "Closing date",
      offers: "Offers",
      documents: "Documents",
      requiredDocuments: "Required documents",
      bid: "Bid",
      startingPrice: "Starting price",
      pointsTable: "Points table",
      priceTable: "Price table",
      deliveryDate: "Delivery date",
      sendYourOffer: "Send your offer",
      closed: "Tender is closed",
      apply: "Apply",
      preview: "Preview",
      publishModal: {
        headerText: "Publish this tender",
        areYouSure: "Are you sure?",
        note: "You can’t edit your tender after publishing it"
      },
      offerModal: {
        headerText: "Submit this offer",
        note: "Do you want to submit this offer?"
      },
      pointsTableHeader: {
        item: "Item",
        requirement: "Requirement",
        points: "Points",
        offer: "Offer",
        total: "Total"
      },
      priceTableHeader: {
        item: "Item",
        unit: "Unit",
        quantity: "Quantity",
        price: "Price",
        total: "Total"
      },
      totalTableTitles: {
        TOTAL_HT: "TOTAL HT",
        TVA: "TVA",
        TOTAL_TTC: "TOTAL TTC"
      }
    },
    ccp: {
      CCP: "CCP",
      CCPInfo: "CCP info",
      fullName: "Full name",
      RIP: "RIP",
      address: "Address",
      paymentProof: "Payment proof",
      uploadPaymentProof: "Click to upload the payment proof",
      IPayed: "I payed",
      confirmPayment: "Confirm Payment",
      didYouPaid: "Have you paid",
      YesIPaid: "Yes i paid"
    },
    chat: {
      message: "Message"
    },
    companyProfile: {
      title: "Company profile",
      interestsUpdated: "Interests updated",
      name: "Name",
      address: "Address",
      website: "Website",
      updateInterests: "Update interests",
      updated: "Updated.",
      withdrawRequestSent: "Withdraw request sent.",
      withdrawRequestProcess: "Your withdraw request is in process.",
      bankAccount: "Bank Account",
      RIPNumber: "RIP number",
      wantToWithdraw: "Do you want to withdraw",
      fillBank: "Please fill the bank account details first",
      minimumWithdrawal: "Your balance is under the minimum withdrawal: 10,000.OO DZD",
    },
    myProfile: {
      title: "Profile",
      passwordUpdated: "Password updated!",
      passwordError: "Error: password is incorrect",
      passwordError2: "Error : new password is too short",
      fullName: "Full name",
      birthDate: "Birth Date",
      address: "Address",
      email: "Email",
      phone: "Phone",
      company: "Company",
      updateInterests: "Update interests",
      updatePassword: "Update password",
      oldPassword: "Old password",
      newPassword: "New password"
    },
    notifications: {
      title: "Notifications",
      markAsRead: "Mark as read",
      earlier: "Earlier",
      new: "New",
      from: "from",
      newTender: "New tender",
      newOffer: "New offer",
      congrats: "Congrats",
      withdrawAccepted: "Your withdraw request has been accepted",
      withdrawRejected: "Your withdraw request has been rejected",
      paymentAccepted: "Your payment proof has been accepted",
      paymentRejected: "Your payment proof has been rejected",
      yourTenderOffers: "You got",
      yourTenderOffers2: "new offers",
      yourOfferAccepted: "Your offer has been accepted",
      yourOfferSelected: "Your offer has been selected",
      yourOfferCanceled: "Your offer has been canceled",
      yourOfferReplyAccepted: "Your offer review-step reply has been accepted",
      yourOfferReplyRejected: "Your offer review-step reply has been rejected",
      noNotificationsYet: "You don’t have any notifications yet",
      yourRequestAccepted: "Your join request has been accepted",
      yourRequestRejected: "Your join request has been rejected",
      yourGroupRequests: "You got",
      yourGroupRequests2: "join requests"
    },
    offer: {
      title: "Offer",
      reviewOptions: "Review options",
      reviewSteps: "Follow-up Steps",
      cancelSelection: "Cancel selection",
      reasonOfAccepting: "Reason of accepting",
      reasonOfCancelling: "Reason of cancelling",
      reasonOfSelecting: "Reason of selecting",
      summary: "Summary",
      points: "Points",
      bidAmount: "Bid amount",
      submissionDate: "Submission date",
      pointsTableTitle: "Points table",
      priceTableTitle: "Price table",
      totalTitle: "Total",
      selectOffer: "Select offer",
      selectThisOffer: "Select this offer",
      whyChooseOfferText: "Please write a brief explanation of why you choose this offer? Keep in mind that the reason for this selection will be shown to all participants",
      rejectThisOffer: "Reject this offer",
      whyRejectOfferText: "Please write a brief explanation of why you rejected this offer? Keep in mind that the reason for this reject will be shown to all participants"
    },
    offerReview: {
      title: "Offer review",
      failed: "Failed",
      passed: "Passed",
      points: "Points",
      yourOfferPoints: "Your offer points",
      requiredPoints: "Required points",
      result: "Result",
      payAndSubmit: "Pay and submit",
      sendOffer: "Send this offer",
      warning: "Warning!",
      minimumPoints: "You don’t have the minimum points!",
      submitAnyway: "Submit anyway?",
      submitOffer: "Submit this offer?"
    },
    offerSent: {
      title: "Offer sent!",
      text: "You will receive an alert whether your offer has been selected on the closing day",
      buttonText: "Go To Homepage"
    },
    paymentMethods: {
      title: "Payment methods",
      paymentMethod: "Payment method",
      paymentReviewType: "Payment review type",
      paymentReviewDuration: "Payment review duration"
    },
    paymentReview: {
      title: "Payment under review",
      text: "Our team will review your payment transaction, You will receive an alert whether your offer has been selected on the closing day",
      buttonText: "Go To Homepage"
    },
    receivedOffers: {
      title: "Received Offers",
      closingDateMsg: "Offers will be displayed on the closing date",
      selectedOffer: "Selected offer",
      acceptedOffer: "Accepted offer",
      tabs: {
        passed: "Passed",
        failed: "Failed"
      }
    },
    reviewSteps: {
      title: "Follow-up steps",
      note: "Note: if all the steps are accepted the offer will automatically accepted",
      replyUnReview: "Your reply is under review,  You will receive an alert with the updates",
      replyRefused: "Your reply has been refused",
      reason: "Reason",
      reviewers: "Reviewers",
      uploadFiles: "Upload files",
      clickToUpload: "Click to upload files",
      reply: "Reply",
      status: "Status",
      noReply: "No reply yet",
      documents: "Documents",
      accept: "Accept",
      reject: "Reject",
      send: "Send",
      back: "Back",
      next: "Next",
      rejectStep: "Reject this step",
      whyRejectStep: "Please write a brief explanation of why this step has been rejected ?",
      sendingReply: "Sending reply",
      confirmSendingReply: "Are you sure sending this reply ?",
      acceptReply: "Accept reply",
      confirmAcceptReply: "Would you like to accept this reply ?",
      statuses: {
        pending: "Pending",
        refused: "Refused",
        accepted: "Accepted"
      }
    },
    reviewStepsPreview: {
      title: "Follow-up steps"
    },
    tenderClosed: {
      title: "Tender closed",
      text: "All Follow-up steps passed successfully",
      text2: "Tender closed and a notification sent to all bidders",
      close: "Close"
    },
    tenderPublished: {
      title: "Tender published",
      text: "Your new tender has been successfully published",
      text2: "You will receive notifications when you have offers from bidders",
      goHome: "Go To Homepage"
    },
    userProfile: {
      publishedTenders: "Published tenders",
      noTenders: "No tenders to show."
    },
    companies: {
      title: "Companies"
    },
    callLines: {
      paragraph1: "Please review the 'Frequently Asked Questions' page, or contact our technical support team by clicking on the 'Chat Us' button",
      paragraph2: "If you cannot find the help you are looking for get in touch with us by calling us at these numbers"
    },
    forgetPassword: {
      emailPage: {
        title: "Forgot password?",
        description: "Enter your registered email below to receive password reset instruction",
        rememberPassword: "Remember password?"
      },
      resetPage: {
        title: "Email has been sent!",
        description: "We have sent a 6 digit code to your Email, use that code to reset your password.",
        code: "Code",
        codeRequired: "Code is required",
        codeInvalid: "This code is invalid",
        resetPassword: "Reset password",
        notReceived: "Didn't receive the code?"
      },
      successPage: {
        title: "Password reset!",
        description: "Your password has been reset successfully.",
      }
    }
  },
  components: {
    offerCard: {
      bestOffer: "Best offer",
      selected: "Selected",
      accepted: "Accepted",
      pending: "Pending",
      rejected: "Rejected",
      points: "Points",
      bidAmount: "Bid amount",
      submissionDate: "Submission date"
    },
    resend: {
      resend: "Resend"
    },
    searchInput: {
      search: "Search"
    },
    tenderCard: {
      posted: "Posted",
      save: "Save",
      report: "Report",
      closingDate: "Closing date",
      offers: "Offers",
      sendReport: "Send report",
      reason: "Reason"
    },
    userNoTendersYet: {
      noTenders: "You don't have tenders yet!",
      createTender: "Create Tender"
    },
    userNoGroupsYet: {
      noGroups: "You don't have groups yet!",
      createGroup: "Create Group buying",
      noGroupsYet: "There is no groups yet!",
      noRequestsYet: "There is no requests!"
    },
    userWithTitle: {
      select: "Select"
    },
    selectList: {
      selectAll: "Select all"
    },
    noTendersYet: "There is no tenders yet!",
    outgoingOfferCard: {
      yourPoints: "Your points"
    },
    noItemYet: {
      noCompaniesYet: "There is no companies to show",
      noOffers: "There is no offers"
    },
    subscription: {
      oneMonth: "One month",
      threeMonths: "3 months",
      sixMonths: "6 months",
      oneYear: "One year",
      billedMonthly: "Billed monthly",
      billedTrimsterly: "Billed trimsterly",
      billedSemesterly: "Billed semesterly",
      billedYearly: "Billed yearly",
      save: "save"
    },
    subscriptionExpired: {
      modalHeader: "Subscription Has Expired",
      modalText: "**Oops! It looks like your subscription has expired.**",
      modalText2: "To continue using this feature, please renew your subscription.",
      renewSubscription: "Renew subscription",
      back: "Back"
    }
  },
  drawer: {
    home: "Home",
    yourTenders: "Your tenders",
    teamManagement: "Team management",
    SavedTenders: "Saved tenders",
    outgoingOffers: "Outgoing offers",
    downloads: "Downloads",
    statistics: "Statistics",
    settings: "Settings",
    subscriptions: "Subscriptions",
    journal: "Journal",
    content: {
      at: "at",
      signOut: "Sign out",
      signOutQuestion: "Would you like to sign-out ?"
    },
    screens: {
      teamManagement: {
        title: "Team Management",
        description: "Manage your company's members",
        select: "Select",
        members: "Members",
        addMember: "Add to new member",
        email: "Email",
        fullName: "Full name",
        memberRole: "Member role",
        userPermissions: "User permissions",
        sendInvitation: "Send invitation",
        editMember: "Edit member",
        deleteMember: "Delete member",
        deleting: "Deleting",
        delete: "Delete",
        confirmDelete: "Would you like to delete this member ?",
        permissions: {
          owner: "Owner",
          manageMembers: "Manage members",
          publishTenders: "Publish tenders",
          sendOffers: "Send offers",
          readReplyMessages: "Read reply messages",
          accessStatistics: "Access statistics",
          accessCompanySettings: "Access company settings",
          manageGroupBuying: "Manage group buying"
        }
      },
      savedTenders: {
        title: "Saved tenders",
        description: "Find your saved tenders",
        noTenders: "You don't have any saved tenders yet!"
      },
      outgoingOffers: {
        title: "Outgoing offers",
        description: "Here you can follow the progress of the tenders in which you submitted offers",
        noOffers: "You don't have any outgoing offer yet!",
        browseTenders: "Browse tenders"
      },
      downloads: {
        title: "Downloads",
        description: "Find the files you downloaded with their details.",
        noDownloads: "You don't have downloads yet!",
        fileItem:{
          source: "Source",
          here: "Here",
          date: "Date",
        }
      },
      subscriptions: {
        title: "Subscriptions",
        yourSubscription: "Your Current Subscription",
        state: "State",
        expireOn: "Expire on",
        expiredOn: "Expired on",
        active: "Active",
        expired: "Expired",
        choosePaymentMethod: "Choose payment method"
      },
      statistics: {
        title: "Statistics",
        description: "A summary about all your account activities",
        totalOffersReceived: "Total offers received",
        offers: "Offers",
        offersAccepted: "Offers accepted",
        offersRejected: "Offers rejected",
        totalOffersSent: "Total offers sent"
      },
      settings: {
        title: "Settings",
        description: "Manage your company's account settings and choose your preferences",
        messaging: "Messaging",
        receiveMessages: "Receive messages from users",
        appearance: "Appearance",
        language: "Language",
        deviceLanguage: "Device Language",
        arabic: "Arabic",
        english: "English",
        french: "French",
        notifications: "Notifications",
        receiveNotifications: "Receive notifications",
        account: "Account",
        deleteAccount: "Delete my account",
        deleteAccountConfirm: "Are you sure?, Your profile and related account information will be deleted from our platform.",
        deleteCompany: "Delete company profile too?",
      }
    },
    groupBuying: {
      title: "Group buying",
      myGroups: "My Groups",
      create: "Create group buying",
      created: "Group buying created",
      createdMsg: "Group buying has been created.",
      previewText: "Preview",
      requests: "Requests",
      itemsTable: {
        title: "Items table",
        description: "Create your own items table so the participants can fill it",
        groupItemsTable: "Group items table",
      },
      publishModal: {
        headerText: "Publishing",
        areYouSure: "Are you sure?",
        note: "You can’t edit after publishing"
      },
      apply:{
        yourRequest: "Your request",
        modalHeader: "Send request",
        modalNote: "Do you want to send this request?",
        requestSent: "Request sent",
        requestSentMsg: "Your request has been sent.",
        join: "Join"
      },
      tabs:{
        explore: "Explore",
        myGroups: "My Groups",
        myRequests: "My Requests",
      },
      noPendingRequests: "There is no pending requests",
      noAcceptedRequests: "There is no accepted requests",
      receivedRequestsTitle: "Join Requests",
      acceptRequest: "Accept this request",
      acceptRequestMsg: "Would you like to accept this request?",
      rejectRequest: "Reject this request",
      rejectRequestMsg: "Would you like to reject this request?",
      updateRequest: "Update The Request",
      update: "Update",
      close: "Close",
      closed: "Closed",
      closeThisGroup: "Close this group",
      closeMsg: "Would you like to close this group?",
      createTender: "Publish Tender",
      note: "Note",
      createTenderNote: "You have to close the group in order to publish the tender",
    }
  },
  stack: {
    webMenu: {
      home: "Home",
      createTender: "Create Tender",
      inbox: "Inbox",
      articles: "Articles",
      help: "Help"
    }
  },
  tabs: {
    home: "Home",
    inbox: "Inbox",
    add: "Add",
    articles: "Articles",
    help: "Help"
  },
  misc: {
    currency: "DZD",
    next: "Next",
    cancel: "Cancel",
    select: "Select",
    reject: "Reject",
    publish: "Publish",
    submit: "Submit",
    done: "Done",
    total: "Total",
    complete: "Complete",
    city: "City",
    branch: "Branch",
    search: "Search",
    states: "States",
    branches: "Branches",
    signIn: "Sign in",
    signUp: "Sign up",
    tryAgain: "Try again",
    confirm: "Confirm",
    particular: "Particular",
    professional: "Professional",
    serverError: "Server Error",
    errorTryAgain: "There was an error please try again later",
    Ok: "Ok",
    price: "Price",
    filesToUpload: "Files to upload",
    to: "To",
    SARLSafakat: "SARL Safakat",
    edit: "Edit",
    save: "Save",
    update: "Update",
    balance: "Balance",
    withdraw: "Withdraw",
    reason: "Reason",
    darkMode: "Dark Mode",
    tenders: "Tenders",
    add: "Add",
    send: "Send",
    optional: "(optional)",
    clickToUpload: "Click to upload",
    max: "Max",
    points: "Points",
    yes: "Yes",
    no: "No",
    deletedUser: "[deleted_user]",
    status: "Status"
  },
  validation: {
    states: "Select at least one (1) state",
    state: "City is required",
    branches: "Branches is required",
    branches2: "Select at least one (1) branch",
    commercialRegisterFront: "Front-page is required",
    commercialRegisterBack: "Back-page is required",
    role: "Company role is too short",
    companyName: "Company name is too short",
    nameRequired: "Name is required",
    nameShort: "Name is too short",
    invalidEmail: "Email is invalid",
    emailTaken: "This email is taken",
    oldPass: "Old password is required",
    newPass: "New password is required",
    newPass2: "Password is too short",
    startupLabel: "The 'startup' label is required"
  }
}
