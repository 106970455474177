import React, {useState, useEffect, useRef} from 'react';
import {MaterialIcons, MaterialCommunityIcons, Feather} from "@expo/vector-icons";

import {
  View, Text, Icon, Avatar, Center, ScrollView, Pressable,
  Input, InputGroup, InputLeftAddon, Button, ZStack, FormControl, Spinner, useColorModeValue,
} from "native-base";
import apiClient from "../utils/apiClient";
import UserModel from "../models/UserModel";
import datePickerTheme from "../theme/datePickerTheme";
import {DatePickerModal} from "react-native-paper-dates";
import {Provider as PaperProvider} from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import SelectList from "../components/SelectList";
import UserWithTitle from "../components/UserWithTitle";
import {localInfo, translate} from "../utils/Translate";
import {Platform} from "react-native";
import {getBase64File} from "../utils/FileTools";
import {AuthContext} from "../utils/context/auth";
import CountryStatesSelectList from "../components/CountryStatesSelectList";

const Title = ({ title, size = 28 }) => {
  return <View>
    <Text textAlign={"left"} fontSize={size} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

export default ({ navigation }) => {

  const { authUser, isCompany } = React.useContext(AuthContext);

  const [showEdit, setShowEdit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [myProfile, setMyProfile] = useState(UserModel)
  const [openDate, setOpenDate] = React.useState(false);
  const [date, setDate] = React.useState(undefined);
  const [userAvatar, setUserAvatar] = React.useState(null);
  const [inputErrors, setInputErrors] = React.useState({});
  const [oldPass, setOldPass] = React.useState('');
  const [newPass, setNewPass] = React.useState('');
  const [interestsUpdated, setInterestsUpdated] = React.useState('');
  const [showOldPass, setShowOldPass] = React.useState(false);
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [passError, setPassError] = React.useState('');
  const [updatePassIsLoading, setUpdatePassIsLoading] = React.useState(false);
  const [updateIsLoading, setUpdateIsLoading] = React.useState(false);
  const [passwordUpdated, setPasswordUpdated] = React.useState('');
  const [interestedStates, setInterestedStates] = useState([])
  const [interestedBranches, setInterestedBranches] = useState([])
  const [states, setStates] = useState([])
  const [branches, setBranches] = useState([])
  const [hasAccess, setHasAccess] = useState(false);
  const [currentUser, setCurrentUser] = useState();

  const selectedImage = useRef();

  const EditButton = () => (
    <View>
      <Button
        bg="appAccent" borderRadius={10} mb={4}
        leftIcon={<Icon as={MaterialIcons} name="mode-edit" size="sm" />}
        onPress={() => setShowEdit(true)}
      >
        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
          {translate('misc.edit')}
        </Text>
      </Button>
    </View>
  )

  const SaveButton = () => (
    <View>
      <Button
        borderRadius={10}
        mb={4}
        leftIcon={<Icon as={MaterialIcons} name="save" size="sm" />}
        onPress={() => updateMyProfile()}
      >

        {
          !isSaving ? (
            <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
              {translate('misc.save')}
            </Text>
          ) : null
        }
        {
          isSaving ? (
            <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
          ) : null
        }
      </Button>
    </View>
  )

  const SaveOrEdit = showEdit ? SaveButton : EditButton;

  const inputWidth = {
    base: "50%",
    md: "80%"
  }

  useEffect(() => {
    (async() => {
      const user = await authUser()
      setCurrentUser(user)
    })()

    getMyProfile()
    getInterests()
    getBranches()

    navigation.addListener('focus', () => {
      getMyProfile()
    });
  }, [navigation])

  function getMyProfile(){
    apiClient.get(`users`).then( response => {

      if (response.data.company_id !== null){
        setHasAccess(isOwnerOrHaveAccess(response.data.permissions))
      }

      setMyProfile(response.data)
    }).catch(error => console.log(error));
  }

  function isOwnerOrHaveAccess(permissions){
    let index = permissions.findIndex(e => e.name === 'owner' || e.name === 'access settings')
    return index !== -1;
  }

  function updateMyProfile(){
    setIsSaving(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify(removeNulls(myProfile)))

    if (!!userAvatar && Platform.OS !== "web"){
      data.append('avatar', {
        uri: userAvatar.uri,
        type: `${userAvatar.type}/${getFileType(userAvatar)}`,
        name: getFileName(userAvatar)
      })
    }

    if (Platform.OS === "web" && !!selectedImage){
      data.append('avatar', selectedImage.current)
    }

    apiClient.post(`users`, data, {isFormData: true}).then( () => {
      setIsSaving(false)
      setShowEdit(false)
      getMyProfile()
    }).catch(error => {
      setIsSaving(false)
      console.log(error.response.data)
    });
  }

  function getBranches(){
    apiClient.get(`branches`).then(response => {
      setBranchesOfLanguage(response.data, localInfo.local)
    }).catch(error => console.log(error.response.data));
  }

  function getInterests(){
    let currentUserId = !!currentUser ? currentUser.id : 1;

    apiClient.get(`users/${currentUserId}/interests`).then(response => {
      setBranchesOfLanguage(response.data.interestedBranches, localInfo.local, true)
      setStatesOfLanguage(response.data.interestedStates, localInfo.local, true)
    }).catch(error => console.log(error.response.data));
  }

  function setBranchesOfLanguage(branches, language, isInterests = false){
    if (isInterests){
      const allIds = branches.map(({id}) => id)
      setInterestedBranches(allIds)
      return;
    }
    let getBranches = branches.map(branch => ({
      id: branch.id,
      name: language === 'ar' ? branch.name.ar :
        language === 'fr' ? branch.name.fr : branch.name.en
    }))

    setBranches(getBranches)
  }

  function setStatesOfLanguage(states, language, isInterests = false){

    if (isInterests){
      const allIds = states.map(({id}) => id)
      setInterestedStates(allIds)
      return;
    }

    let getStates = states.map(state => ({
      id: state.id,
      name: language === 'ar' ? state.name.ar :
        language === 'fr' ? state.name.fr : state.name.en
    }))

    setStates(getStates);
  }

  const handleFullNameText = (text) => {
    setMyProfile({
      ...myProfile,
      full_name: text
    })
  }
  const handleAddressText = (text) => {
    setMyProfile({
      ...myProfile,
      address: text
    })
  }
  const handleEmailText = (text) => {
    setMyProfile({
      ...myProfile,
      email: text
    })
  }
  const handlePhoneText = (text) => {
    setMyProfile({
      ...myProfile,
      phone_number: text
    })
  }

  const onDismissSingle = () => {
    setOpenDate(false);
  };
  const onConfirmSingle = (params) => {
    setOpenDate(false);
    setDate(params.date);

    setMyProfile({
      ...myProfile,
      birth_date: getFormattedDate(params.date)
    })
  }

  function getFormattedDate(date){
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
  }

  function removeNulls(data){
    for (const key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    delete data.avatar

    return data;
  }

  const pickImage = async () => {
    let image = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });

    if (image.cancelled) return

    if (Platform.OS === "web"){
      getBase64File(image.uri).then(file => selectedImage.current = file)
    }

    setUserAvatar(image.assets[0]);
  };

  const handleOldPass = (text) => {
    setOldPass(text)
    if (oldPass.length === 0){
      let errors = {...inputErrors}
      delete errors.oldPass
      setInputErrors(errors);
    }
  }

  const handleNewPass = (text) => {
    setNewPass(text)
    if (newPass.length < 6 ){
      let errors = {...inputErrors}
      delete errors.newPass
      setInputErrors(errors);
    }
  }

  let onStateChanged = (item) => {
    setInterestedStates(item)

    let error = {...inputErrors}
    delete error.state
    setInputErrors(error);
  }

  let onBranchChanged = (item) => {
    setInterestedBranches(item)

    let error = {...inputErrors}
    delete error.branches
    setInputErrors(error);
  }

  const validate = () => {

    if (oldPass.length === 0) {
      setInputErrors({ ...inputErrors,
        oldPass: translate('validation.oldPass')
      });
      return false;
    } else if (newPass.length === 0) {
      setInputErrors({ ...inputErrors,
        newPass: translate('validation.newPass')
      });
      return false;
    } else if (newPass.length < 6) {
      setInputErrors({ ...inputErrors,
        newPass: translate('validation.newPass2')
      });
      return false;
    }

    return true;
  };

const validateInterests = () => {

    if (interestedStates.length === 0) {
      setInputErrors({ ...inputErrors,
        state: translate('validation.states')
      });
      return false;
    } else if (interestedBranches.length === 0) {
      setInputErrors({ ...inputErrors,
        branches: translate('validation.branches2')
      });
      return false;
    }

    return true;
  };

  const handleUpdatePass = () => {
    if (!validate()) return

    setUpdatePassIsLoading(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      oldPass, newPass
    }))

    apiClient.post(`users`, data, {isFormData: true}).then( () => {
      setUpdatePassIsLoading(false)
      setPasswordUpdated(translate('screens.myProfile.passwordUpdated'))
      setPassError('')
      setOldPass('')
      setNewPass('')
    }).catch(error => {

      setUpdatePassIsLoading(false)
      setPasswordUpdated('')
      // show server errors
      if (error.response.status === 400){
        if (error.response.data === 'old-incorrect'){
          setPassError(translate('screens.myProfile.passwordError'))
        } else if (error.response.data === 'too-short'){
          setPassError(translate('screens.myProfile.passwordError2'))
        }
      }
    });
  }

  const handleUpdateInterests = () => {
    if (!validateInterests()) return

    setUpdateIsLoading(true)

    const data = new FormData()
    data.append("_method", "PUT")
    data.append('data', JSON.stringify({
      interestedBranches,
      interestedStates,
    }))

    apiClient.post(`users`, data, {isFormData: true}).then( () => {
      setInterestsUpdated(translate('screens.companyProfile.interestsUpdated'))
      setUpdateIsLoading(false)
    }).catch(error => {
      setUpdateIsLoading(false)
    });
  }

  function getFileName(file){
    return file.uri.substring(file.uri.lastIndexOf("/") + 1);
  }
  function getFileType(file){
    return file.uri.substring(file.uri.lastIndexOf(".") + 1);
  }

  const handleEditCompany = () => {
    navigation.navigate('CompanyProfile', {companyId: myProfile.company_id})
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">

      <ScrollView>
        <View m={4}>
          <Title title={translate('screens.myProfile.title')} />
        </View>

        {
          !!myProfile.id ? (
            <Center>
              <Pressable
                onPress={ showEdit ? pickImage : null}
              >
                <Avatar
                  mt={2}
                  size="2xl"
                  source={{ uri: !!userAvatar ? userAvatar.uri : myProfile.avatar }}
                />
              </Pressable>

              {
                showEdit ? (
                  <ZStack>
                    <View ml={8} mt={-12} bg={'#ccc'} p={2} rounded="full">
                      <Icon
                        as={<MaterialCommunityIcons name="camera"/>} color="appAccent" size={7}
                        onPress={ showEdit ? pickImage : null}
                      />
                    </View>
                  </ZStack>
                ) : null
              }

              <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={30} >
                {
                  myProfile.full_name
                }
              </Text>
              {
                !!myProfile.company_id ? (
                  <View>
                    {
                      localInfo.isRTL ? (
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={19}>
                          {myProfile.company.name} {translate('screens.home.at')} {myProfile.company_role}
                        </Text>
                      ) : (
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={19}>
                          {myProfile.company_role} {translate('screens.home.at')} {myProfile.company.name}
                        </Text>
                      )
                    }

                  </View>
                ) : null
              }
            </Center>
          ) : null
        }

        {
          !!myProfile ? (
            <View bg="#F4F4F4" mx={1} my={8} p={2} borderRadius={10}>

              <SaveOrEdit />

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={40}>
                      <Icon as={<MaterialIcons name="badge" />} color={"appPrimary"} size={5} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={14} fontWeight={500}>
                          {translate('screens.myProfile.fullName')}
                        </Text>
                      </Center>
                    </View>
                  } />
                <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  isReadOnly={!showEdit}
                  fontSize={16}
                  value={myProfile.full_name}
                  onChangeText={handleFullNameText}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.myProfile.fullName')} />
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={40}>
                      <Icon as={<MaterialIcons name="celebration" />} color={"appPrimary"} size={5} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={14} fontWeight={500}> {translate('screens.myProfile.birthDate')}</Text>
                      </Center>
                    </View>
                  } />
                <Pressable w="100%" onPress={() => showEdit ? setOpenDate(true) : null}>
                  <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} w={inputWidth} placeholder={translate('screens.myProfile.birthDate')}
                         bg="primary.50" fontSize={16}
                         value={myProfile.birth_date} isReadOnly size="xl"
                         pointerEvents="none" py={3}
                  />
                </Pressable>
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={40}>
                      <Icon as={<MaterialCommunityIcons name="map-marker" />} color={"appPrimary"} size={5} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={14} fontWeight={500}> {translate('screens.myProfile.address')}</Text>
                      </Center>
                    </View>
                  } />
                <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  onChangeText={handleAddressText}
                  value={myProfile.address}
                  isReadOnly={!showEdit}
                  fontSize={16}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.myProfile.address')} />
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={40}>
                      <Icon as={<MaterialIcons name="mail" />} color={"appPrimary"} size={5} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={14} fontWeight={500}> {translate('screens.myProfile.email')}</Text>
                      </Center>
                    </View>
                  } />
                <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  fontSize={16}
                  isReadOnly
                  isDisabled
                  value={myProfile.email}
                  onChangeText={handleEmailText}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.myProfile.email')} />
              </InputGroup>

              <InputGroup mb={1} >
                <InputLeftAddon
                  children={
                    <View flexDirection="row" w={40}>
                      <Icon as={<Feather name="smartphone" />} color={"appPrimary"} size={5} />
                      <Center>
                        <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={14} fontWeight={500}> {translate('screens.myProfile.phone')}</Text>
                      </Center>
                    </View>
                  } />
                <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
                  fontSize={16}
                  isReadOnly
                  isDisabled
                  value={myProfile.phone_number}
                  onChangeText={handlePhoneText}
                  bg="primary.50"
                  w={inputWidth} placeholder={translate('screens.myProfile.phone')} />
              </InputGroup>

            </View>
          ) : null
        }

        <PaperProvider theme={datePickerTheme}>
          <DatePickerModal
            locale="en"
            mode="single"
            visible={openDate}
            onDismiss={onDismissSingle}
            date={date}
            onConfirm={onConfirmSingle}
            validRange={{
              endDate: new Date(2999, 1, 1),
            }}
            // onChange={} // same props as onConfirm but triggered without confirmed by user
            // saveLabel="Save" // optional
            // uppercase={false} // optional, default is true
            // label="Select date" // optional
            // animationType="slide" // optional, default is 'slide' on ios/android and 'none' on web
          />
        </PaperProvider>


        {
          !!myProfile.company_id ? (
            <View>
              <View m={4} mt={2} mb={2}>
                <Title title={translate('screens.myProfile.company')} size={24} />
              </View>

              <View bg="#F4F4F4" mx={4} my={4} p={3} borderRadius={10}>
                <UserWithTitle
                  avatar={myProfile.company.avatar}
                  name={myProfile.company.name}
                  title={myProfile.company_role}
                  avatarSize="lg"
                  nameFontSize={20}
                  onlyUser={true}
                  onEdit={!!myProfile.company_id && hasAccess ? handleEditCompany : undefined}
                />
              </View>

            </View>
          ) : null
        }

        {
          !isCompany ? (
            <View>
              <View m={4} mt={2} mb={2}>
                <Title title={translate('screens.myProfile.updateInterests')} size={24} />
              </View>

              <View bg="#F4F4F4" mx={4} my={4} p={3} borderRadius={10}>
                <InputGroup mb={1} >
                  <InputLeftAddon
                    children={
                      <View flexDirection="row" w={24}>
                        <Icon as={<MaterialCommunityIcons name="source-branch" />} color={"appPrimary"} size={6} />
                        <Center>
                          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('misc.branches')}</Text>
                        </Center>
                      </View>
                    } />
                  <View w={inputWidth}>
                    {
                      branches.length ? (
                        <SelectList
                          selectText={translate('misc.branches')}
                          modalHeaderText={translate('screens.auth.interests.selectBranches')}
                          doneText={translate('misc.done')}
                          cancelText={translate('misc.cancel')}
                          borderColor="gray.200"
                          roundedSize={2}
                          onDone={onBranchChanged}
                          alreadySelectedItems={interestedBranches}
                          items={branches}
                        />
                      ) : null
                    }
                  </View>
                </InputGroup>
                {
                  'branches' in inputErrors ? (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} color="appSecondary">
                      {inputErrors.branches}
                    </Text>
                  ) : null
                }

                <InputGroup mb={1} >
                  <InputLeftAddon
                    children={
                      <View flexDirection="row" w={24}>
                        <Icon as={<MaterialCommunityIcons name="city" />} color={"appPrimary"} size={6} />
                        <Center>
                          <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={16} fontWeight={500}> {translate('misc.states')}</Text>
                        </Center>
                      </View>
                    } />
                  <View w={inputWidth}>
                    <CountryStatesSelectList
                      selectText={translate('misc.states')}
                      modalHeaderText={translate('screens.auth.interests.selectCountryStates')}
                      doneText={translate('misc.done')}
                      cancelText={translate('misc.cancel')}
                      borderColor="gray.200"
                      roundedSize={2}
                      onDone={onStateChanged}
                      alreadySelectedItems={interestedStates}
                    />
                  </View>
                </InputGroup>
                {
                  'state' in inputErrors ? (
                    <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} mb={2} color="appSecondary">
                      {inputErrors.state}
                    </Text>
                  ) : null
                }

                {
                  interestsUpdated.length > 0 ? (
                    <View mt={2}>
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="appPrimary">
                        {interestsUpdated}
                      </Text>
                    </View>
                  ) : null
                }

                <Button
                  bg="appAccent"
                  borderRadius={10} mt={10}
                  onPress={handleUpdateInterests}
                >
                  {
                    !updateIsLoading ? (
                      <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
                        {translate('misc.update')}
                      </Text>
                    ) : null
                  }
                  {
                    updateIsLoading ? (
                      <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
                    ) : null
                  }
                </Button>

              </View>

            </View>
          ) : null
        }

        <View mb={4}/>

        <View m={4} mt={2} mb={2}>
          <Title title={translate('screens.myProfile.updatePassword')} size={24} />
        </View>

        <View bg="#F4F4F4" mx={4} my={4} p={3} borderRadius={10}>
          <FormControl mt={2} isInvalid={'oldPass' in inputErrors}>
            <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.myProfile.oldPassword')} rounded={10}
              size="xl" borderColor="gray.500" pl={4} py={3}
              autoCapitalize="none" bg={useColorModeValue("white", "coolGray.800")}
              value={oldPass}
              type={showOldPass ? "text" : "password"}
              InputRightElement={<Icon as={<MaterialIcons name={showOldPass ? "visibility" : "visibility-off"} />} size={7} mr="2" color="muted.400" onPress={() => setShowOldPass(!showOldPass)} />}
              onChangeText={handleOldPass}
            />
            {
              'oldPass' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.oldPass}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          <FormControl mt={2} isInvalid={'newPass' in inputErrors}>
            <Input textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"}
              placeholder={translate('screens.myProfile.newPassword')} rounded={10}
              size="xl" borderColor="gray.500" pl={4} py={3}
              autoCapitalize="none" bg={useColorModeValue("white", "coolGray.800")}
              value={newPass}
              type={showNewPass ? "text" : "password"}
              InputRightElement={<Icon as={<MaterialIcons name={showNewPass ? "visibility" : "visibility-off"} />} size={7} mr="2" color="muted.400" onPress={() => setShowNewPass(!showNewPass)} />}
              onChangeText={handleNewPass}
            />
            {
              'newPass' in inputErrors ? (
                <FormControl.ErrorMessage>
                  {inputErrors.newPass}
                </FormControl.ErrorMessage>
              ) : null
            }
          </FormControl>

          {
            passError.length > 0 ? (
              <View mt={2}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="appSecondary">
                  {passError}
                </Text>
              </View>
            ) : null
          }
          {
            passwordUpdated.length > 0 ? (
              <View mt={2}>
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={20} fontWeight={500} color="appPrimary">
                  {passwordUpdated}
                </Text>
              </View>
            ) : null
          }

          <Button
            bg="appAccent"
            borderRadius={10} mt={10}
            onPress={handleUpdatePass}
          >
            {
              !updatePassIsLoading ? (
                <Text textAlign={localInfo.isRTL && Platform.OS !== 'web' ? "right" : "left"} fontSize={18} fontWeight={500} color="primary.50">
                  {translate('misc.update')}
                </Text>
              ) : null
            }
            {
              updatePassIsLoading ? (
                <Spinner size="sm" color="white" accessibilityLabel="Loading..." />
              ) : null
            }
          </Button>

        </View>

        <View mb={32}/>

      </ScrollView>
    </View>
  );
}
