import React, {useEffect} from 'react';
import {
  initializeRevenueCat, getOfferingsPackages, getCustomerInfo,
  customerInfoUpdateListener, purchasePackage
} from '../../utils/RevenueCatService';

import {
  View, Text, ScrollView, useColorModeValue,
  HStack, Pressable, Box, Center, Modal, Spinner, Button, Image
} from "native-base";
import {Dimensions, Platform} from "react-native";
import {AuthContext} from "../../utils/context/auth";
import {translate} from "../../utils/Translate";
import SubscriptionCard from "../../components/SubscriptionCard";
import TimeFormatter from "../../utils/TimeFormatter";
import SubscriptionCardSkeleton from "../../components/skeleton/SubscriptionCardSkeleton";
import apiClient from "../../utils/apiClient";

const width = Dimensions.get('screen').width

const Title = ({ title }) => {
  return <View>
    <Text textAlign="left" fontSize={25} fontWeight={500}>{title}</Text>
    <View height={1} backgroundColor="appPrimary" w={60}/>
  </View>
}

const UserSubscription = ({ expireDate, state}) => {
  return (
    <Pressable
      width={[width * 0.96, width * 0.96, width * 0.35]}
      onPress={() => null}>
      <Box
        bg={useColorModeValue("#F8F8F8", "coolGray.700")}
        borderRadius={8} shadow={3} px={3} py={2} mt={4} mb={1} ml={[0, 0, 1]}
      >
        <HStack justifyContent="space-between">
          <Text textAlign="left" fontWeight={500} fontSize={21}>
            {
              !!state ?
                translate('drawer.screens.subscriptions.expireOn')
                : translate('drawer.screens.subscriptions.expiredOn')
            }
            :
          </Text>

          <Text textAlign="left" fontSize={21} fontWeight={500}>
            {TimeFormatter.getFormattedDate(expireDate)}
          </Text>
        </HStack>

        <HStack mt={3} justifyContent="space-between">
          <Text textAlign="left" fontWeight={500} fontSize={21}>
            {translate('drawer.screens.subscriptions.state')}:
          </Text>

          <Text
            textAlign="left"
            color={!!state ? "appPrimary" : "appSecondary"}
            fontSize={21} fontWeight={500}>
            {
              !!state ?
                translate('drawer.screens.subscriptions.active')
                : translate('drawer.screens.subscriptions.expired')
            }
          </Text>
        </HStack>

      </Box>
    </Pressable>
  )
}

export default ({ navigation }) => {

  const { authUser, isCompany } = React.useContext(AuthContext);

  const [availablePackages, setAvailablePackages] = React.useState([]);
  const [userSubInfo, setUserSubInfo] = React.useState(null);
  const [showEPayModal, setShowEPayModal] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState(null);
  const [isGettingCheckoutURL, setIsGettingCheckoutURL] = React.useState(false);
  const [serverError, setServerError] = React.useState(null);

  useEffect(() => {
    const revCat = async () => {
      const user = await authUser()
      const appUserId = isCompany ? user.company.uuid : user.id;
      const offeringsID = isCompany ? user.company.is_startup === 1 ? "startup" : "professional" : "particular"

      if (Platform.OS == "web"){
        getWebSubscriptionInfo(offeringsID)
        return;
      }

      await initializeRevenueCat(appUserId)
      const offeringPackages = await getOfferingsPackages(offeringsID)
      const customerInfo = await getCustomerInfo()

      setUserSubInfo(customerInfo.entitlements.all)
      setAvailablePackages(offeringPackages)

      customerInfoUpdateListener(info => {
        setUserSubInfo(info.entitlements.all)
      })
    }
    revCat().catch(console.log)

    const Interval = setInterval(() => {
      if (Platform.OS == "web"){
        getWebUserSubscriptionInfo()
      }
    }, 30000)

    navigation.addListener('focus', async () => {
      if (Platform.OS != "web"){
        const customerInfo = await getCustomerInfo()
        setUserSubInfo(customerInfo.entitlements.all)
      }
    });

    return function clean (){
      clearInterval(Interval);
    }
  }, [navigation])

  const buyPackage = async (currentPack) => {
      const isSuccess = await purchasePackage(currentPack)
  }

  function getWebUserSubscriptionInfo(){
    apiClient.get(`subscription/user`).then( response => {
      setUserSubInfo(response.data)
    })
  }
  function getWebSubscriptionInfo(offeringsID){
    getWebUserSubscriptionInfo()
    apiClient.get(`subscription/offerings?offertype=${offeringsID}`).then( response => {
      setAvailablePackages(response.data)
    })
  }

  function getEPayCheckOutURL(paymentMethod){
    setIsGettingCheckoutURL(true)

    apiClient.post(`subscription/epay-invoice`, {
      mode: paymentMethod, subscriptionType: selectedPackage.packageType
    }).then( response => {
      setIsGettingCheckoutURL(false)
      // Open the checkout URL
      if (!!response.data){
        window.open(response.data, '_blank').focus();
      } else {
        console.error("Couldn't get checkout URL")
      }
    }).catch(e => {
      setIsGettingCheckoutURL(false)
      setServerError(translate('misc.serverError'))
    })
  }

  return (
    <View bg={useColorModeValue("white", "coolGray.800")} h="100%">
      <ScrollView>

        <View mt={4} ml={3}>
          <Title title={translate('drawer.screens.subscriptions.yourSubscription')} />
        </View>

        {
          !!!userSubInfo ? (
            <SubscriptionCardSkeleton />
          ) : null
        }

        {
          !!userSubInfo && !!userSubInfo.pro ? (
            <View mx={2}>
              <UserSubscription
                expireDate={userSubInfo.pro.expirationDate}
                state={userSubInfo.pro.isActive}
              />
            </View>
          ) : null
        }

        <View mt={8} ml={3}>
          <Title title={translate('drawer.screens.subscriptions.title')} />
        </View>

        <View mt={2}/>

        <View mx={2}>

          {
            !!!availablePackages.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                <SubscriptionCardSkeleton />
                <SubscriptionCardSkeleton />
                <SubscriptionCardSkeleton />
                <SubscriptionCardSkeleton />
              </View>
            ) : null
          }

          {
            !!availablePackages.length ? (
              <View flexWrap="wrap" flexDirection={["column", "column", "row"]}>
                {
                  availablePackages.map((pack, index) => (
                    <View key={index}>
                      <SubscriptionCard
                        showSave={pack.packageType === "ANNUAL"}
                        price={pack.product.priceString}
                        period={pack.packageType}
                        subscriptionPackage={pack}
                        onClicked={async (clickedPack) => {
                          if (Platform.OS == "web"){
                            setSelectedPackage(clickedPack)
                            setShowEPayModal(true)
                            return;
                          }
                          await buyPackage(clickedPack)
                        }}
                      />
                    </View>
                  ))
                }
              </View>
            ) : null
          }

        </View>

        <View mb={40}/>

        {/* CIB/Edahabia Modal */}
        <Center>
          <Modal size={["xl", "xl", "md"]} isOpen={showEPayModal} onClose={() => {
            setShowEPayModal(false)
            setIsGettingCheckoutURL(false)
            setServerError(null)
          }}>
            <Modal.Content>
              <Modal.CloseButton />
              <Modal.Header>
                {translate('drawer.screens.subscriptions.choosePaymentMethod')}
              </Modal.Header>
              <Modal.Body>

                <Text textAlign="center" my={2} fontSize={18}>
                  {translate('drawer.screens.subscriptions.choosePaymentMethod')} :
                </Text>

                <HStack alignItems="center" justifyContent="center">
                  <Pressable
                    onPress={() => {
                      if (isGettingCheckoutURL) return;
                      if (Platform.OS == "web"){
                        getEPayCheckOutURL("CIB")
                      }
                    }}>
                    <Box bg="#F8F8F8" borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 1]}>

                      <Image
                        my={2} mx={2} h={500} size="lg" alt="Alternate Text"
                        source={{uri : 'https://files.safakat.net/images/icone-cib.png'}} />
                    </Box>
                  </Pressable>
                  <View mx={3}/>
                  <Pressable
                    onPress={() => {
                      if (isGettingCheckoutURL) return;
                      if (Platform.OS == "web") {
                        getEPayCheckOutURL("EDAHABIA")
                      }
                    }}>
                    <Box bg="#F8F8F8" borderRadius={8} shadow={3} mt={3} mb={1} ml={[0, 0, 1]}>

                      <Image
                        my={2} mx={2} h={500} size="lg" alt="Alternate Text"
                        source={{uri : 'https://files.safakat.net/images/icone-dahabia.png'}} />
                    </Box>
                  </Pressable>
                </HStack>

                {
                  isGettingCheckoutURL ? (
                    <View mt={10}>
                      <Spinner size="lg" color="appPrimary" accessibilityLabel="Loading more.." />
                    </View>
                  ) : null
                }

                {
                  !!serverError ? (
                    <Text textAlign="center" color="appSecondary" my={2} fontSize={18}>
                      { serverError }
                    </Text>
                  ) : null
                }

              </Modal.Body>
              <Modal.Footer>
                <Button.Group space={2}>
                  <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                    setShowEPayModal(false);
                  }}>
                    {translate('misc.cancel')}
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </Center>

      </ScrollView>
    </View>
  );
}
